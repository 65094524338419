import { Button, message, Modal, Space } from "antd";
import { FC, useState } from "react";
import SavedMessage from "../../../../components/messages/savedMessage";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { activateLeasingCase, expireLeasingCase } from "../../../../services/leasingService";
import LeasingAdminDealActiveInfo from "./leasingAdminDealActiveInfo";
import LeasingAdminDealExpireInfo from "./leasingAdminDealExpireInfo";

type ActionType = "expired" | "activated";

const LeasingActivationActions: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [actionType, setActionType] = useState<ActionType | null>(null);

  const { active, expired, readonly } = leasingCase.flags;
  const disabled = loading;

  const handleAction = async (type: ActionType) => {
    const { id } = leasingCase;
    try {
      setLoading(true);
      const { data } = type === "expired" ? await expireLeasingCase(id) : await activateLeasingCase(id);
      setLeasingCase({ ...leasingCase, ...data });
      message.success(<SavedMessage />);
      setActionType(null);
    } catch ({ response }) {
      const { message: msg } = response?.data;
      message.error(msg || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  let modalInfo = null;
  if (actionType === "activated") {
    modalInfo = <LeasingAdminDealActiveInfo />
  } else if (actionType === "expired") {
    modalInfo = <LeasingAdminDealExpireInfo />
  }

  return (
    <>
      <Space>
        <Button type="primary" disabled={disabled} onClick={() => setActionType("expired")} danger>
          {expired ? _t("expire", "again") : _t("expire")}
        </Button>
        <Button type="primary" disabled={disabled || readonly} onClick={() => setActionType("activated")} color="green">
          {active ? _t("activate", "again") : _t("activate")}
        </Button>
      </Space>

      <Modal
        visible={!!actionType}
        confirmLoading={loading}
        onOk={() => actionType && handleAction(actionType)}
        onCancel={() => setActionType(null)}
        title={_t('confirm_please')}
      >
        {modalInfo}
      </Modal>
    </>
  );
};

export default LeasingActivationActions;
