import { InsuranceCalcMethodType, InsuranceOutputType } from "./insuranceCalcTypes";

const insuranceCalc: InsuranceCalcMethodType = ({
  insuranceInput: input,
  valueConfig: config
}, isPrivate, isInactivePeriod) => {
  const { active, vehiclePrice, isUserOver26, glassDamage, roadAssistance } = input;

  const insuranceCommissionValue = config.insuranceCommission.value || 0;
  // TODO move into configuration? move into enum?
  const INSURANCE_DAMAGE_TAX_RATE = 0.011;
  const INSURANCE_ENVIRONMENT_TAX = 84;

  //Insurance-addons & annually prices
  const insuranceAddOns = {
    roadHelp: 600,
    glassDamage: 1273,
    glassDamageSelfRisk: 1741,
  };

  //Init output
  let output: InsuranceOutputType = {
    insurable: true,
    monthlyPayment: null,
    ownRisk: null,
    roadAssistance: insuranceAddOns.roadHelp,
    glassDamage: insuranceAddOns.glassDamage,
    roadAssistanceMonthlyPayment: Math.ceil(insuranceAddOns.roadHelp / 12 / 10) * 10,
    glassDamageMonthlyPayment: Math.ceil(insuranceAddOns.glassDamage / 12 / 10) * 10,
  };

  if (!active || !vehiclePrice) return output;

  // Check if insurable for under26:
  if (isPrivate) {
    const insuranceClassesUnder26 = insuranceClasses.filter((c) => !!c.priceUnder26);
    const maxPriceUnder26 = insuranceClassesUnder26[insuranceClassesUnder26.length - 1].amount;

    if (vehiclePrice >= maxPriceUnder26 && !isUserOver26) {
      output.insurable = false;
      return output;
    }
  }

  //Calculation
  for (let insuranceClass of insuranceClasses) {
    if ((vehiclePrice || 0) <= insuranceClass.amount) {
      output.monthlyPayment = insuranceClass.price;
      output.ownRisk = insuranceClass.selfRiskPrice;

      if (!isUserOver26) {
        output.monthlyPayment = insuranceClass.priceUnder26 || 0;
        output.ownRisk = insuranceClass.selfRiskPriceUnder26;
        output.insurable = output.monthlyPayment !== null && output.ownRisk !== null;
      }
      break;
    }
  }

  // Skadeforsikringsafgift: Add 1,1% of prize to monthly payment
  // Miljøafgift: Add kr 84,- to monthly payment
  const damageInsuranceTax = (output.monthlyPayment || 0) * INSURANCE_DAMAGE_TAX_RATE;
  output.monthlyPayment = (
    ((output.monthlyPayment || 0) + insuranceCommissionValue) / 12
  ) + damageInsuranceTax
    + INSURANCE_ENVIRONMENT_TAX;
  output.monthlyPayment = Math.ceil(output.monthlyPayment / 10) * 10;

  if (isInactivePeriod) {
    output.monthlyPayment = Math.ceil(output.monthlyPayment / 2);
  }

  if (roadAssistance) output.monthlyPayment += output.roadAssistanceMonthlyPayment;
  if (glassDamage) output.monthlyPayment += output.glassDamageMonthlyPayment;

  return output;
};

export default insuranceCalc;

//Insuranceclasses & annually prices (Prices are updated 1/1-2023)
const insuranceClasses = [
  {
    amount: 100000,
    price: 6155,
    selfRiskPrice: 5571,
    priceUnder26: 12243,
    selfRiskPriceUnder26: 13927,
  },
  {
    amount: 200000,
    price: 7181,
    selfRiskPrice: 5571,
    priceUnder26: 14285,
    selfRiskPriceUnder26: 13927,
  },
  {
    amount: 300000,
    price: 8126,
    selfRiskPrice: 8356,
    priceUnder26: 15500,
    selfRiskPriceUnder26: 13927,
  },
  {
    amount: 400000,
    price: 11141,
    selfRiskPrice: 8356,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 500000,
    price: 11643,
    selfRiskPrice: 11698,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 600000,
    price: 12998,
    selfRiskPrice: 16713,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 750000,
    price: 16340,
    selfRiskPrice: 16713,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
];
