import React, { useState } from "react";
import { Button, Form, message, Space } from "antd";
import _t from "../../lang/translate";
import { saveTaxCaseStatus } from "../../services/taxService";
import { saveTaxDocCaseStatus } from "../../services/taxDocService";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import SwitchInput from "../form/switchInput";
import StatusSelect from "../form/statusSelect";
import { saveImportCaseStatus } from "../../services/importService";
import { CaseStatusUpdate } from "../../types/casesCommonTypes";
import { isAxiosError } from "../../utilities/typeGuard";
import { saveResellerTaxCaseStatus } from "../../services/resellerTaxService";

export default function ChangeStatus<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<CaseStatusUpdate>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, initialValues, context } = payload;

  const handleSave = async (values: CaseStatusUpdate) => {
    try {
      setLoading(true);
      let response: any = null;
      const data = { id: values.id, forcedStatus: !values.autoStatus };
      if (context === "tax") {
        response = await saveTaxCaseStatus(id, data);
      } else if (context === "taxDoc") {
        response = await saveTaxDocCaseStatus(id, data);
      } else if (context === "import") {
        response = await saveImportCaseStatus(id, data);
      } else if (context === "resellerTax") {
        response = await saveResellerTaxCaseStatus(id, data);
      } else {
        throw new Error("unhandled context:" + context);
      }
      message.success(_t("saved"));
      onSuccess(response.data as T);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Form
      form={tmpForm}
      initialValues={{ id: initialValues?.id, autoStatus: !initialValues?.forced }}
      requiredMark={false}
      layout="vertical"
      onFinish={handleSave}
    >
      <h2 className="modal-title">{_t("update_status")}</h2>
      <Form.Item name="autoStatus" valuePropName="checked">
        <SwitchInput disabled={loading} label={_t("auto", "status")} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, cur) => prev.autoStatus !== cur.autoStatus}>
        {({ getFieldValue }) => {
          return (
            <Form.Item name="id">
              <StatusSelect contexts={context} loading={loading} disabled={!!getFieldValue("autoStatus")} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("save")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ChangeStatus);
