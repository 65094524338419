import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { ContactFormType, IdType, ModulTypes, VehicleEquipment } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/misc`;

export const latestCasesUrl = `${apiEndpoint}/latestCases`;

export const caseListUrl = (context: ModulTypes, id: IdType, query?: string) =>
  `${apiEndpoint}/caseList?filter[${context}]=${id}&${getApiQuery(query)}`;

export function scrapeLink(link: string) {
  return http.post(`${apiEndpoint}/scrapeLink`, { link });
}

export function loadEquipmentsByVin(vin: string) {
  return http.get(`${getApiEndpoint()}/vehicleValuation/${vin}/equipments`);
}

export function loadPotentialEquipmentsByVin(vin: string) {
  return http.get<{
    data: { equipments: VehicleEquipment[] }
  }>(`${getApiEndpoint()}/vehicleValuation/${vin}/potentialEquipments`);
}

export function contactSupport(data: ContactFormType) {
  return http.post(`${apiEndpoint}/contactSupport`, {
    message: data.message,
    type: data.type,
    url: window.location.href
  });
}

export function getCVRData(cvr: string) {
  return http.get(`${apiEndpoint}/cvrData/${cvr}`);
}

/** Check if a company of the given CVR is already in the system.
 *
 * Route::get('misc/cvrData/{cvr}/inUse' -> {data: boolean}
*/
export function isCVRInUse(cvr: string) {
  return http.get<{ data: boolean }>(`${apiEndpoint}/cvrData/${cvr}/inUse`);
}

export function toggleEmailSubscription(status: boolean) {
  return http.post<{ data: boolean }>(`${apiEndpoint}/toggleEmailSubscription`, {
    'status': status
  });
}
