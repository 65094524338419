import React, { FC, useState } from "react";
import { Button, Col, message, Row, Spin, Typography } from "antd";
import { ChecklistEntryViewType } from "../../types/checklistTypes";
import CheckBoxRound from "../form/checkBoxRound";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import Expandable from "../expandable";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { updateChecklistEntryCompletion } from "../../services/checklistService";
import ChecklistUserStore from "../../contexts/checklistUserStore";
import { isAxiosError } from "../../utilities/typeGuard";

interface ChecklistItemProps {
  item: ChecklistEntryViewType;
}

const ChecklistUserItem: FC<ChecklistItemProps> = ({ item }) => {
  const { completed, groupId, id, description, title } = item;
  const { expanded, setExpanded, dispatchGroups } = ChecklistUserStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const isExpanded = expanded?.id === id && expanded.groupId === groupId;

  const toggleExpanded = () => {
    const nextState = isExpanded ? null : { groupId, id };
    setExpanded(nextState);
  };

  const setCompleted = async (e: CheckboxChangeEvent) => {
    onStatusChange({ ...item, completed: e.target.checked });
  };

  const onStatusChange = async (item: ChecklistEntryViewType) => {
    try {
      setLoading(true);
      await updateChecklistEntryCompletion(item);
      setLoading(false);
      dispatchGroups({ type: "update_entry", payload: item });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={12} align="middle" className="flex-wrap">
        <Col flex="auto" style={{ minWidth: 0 }}>
          <CheckBoxRound onChange={setCompleted} checked={completed}>
            <Typography.Paragraph
              ellipsis={isExpanded ? false : { rows: 1, expandable: true, symbol: " ", tooltip: title }}
              className="m-0"
            >
              {title}
            </Typography.Paragraph>
          </CheckBoxRound>
        </Col>
        {description && (
          <Col>
            <Button
              type="text"
              onClick={toggleExpanded}
              shape="circle"
              size="small"
              className={`expand-chevron ${isExpanded ? "rotate-180" : ""}`}
              icon={<Icon name="chevron-down-outline" />}
            />
          </Col>
        )}
      </Row>
      {description && (
        <Expandable expanded={isExpanded}>
          <div className="bg-muted mt-1 mb-05 p-1 border-rounded">{description}</div>
        </Expandable>
      )}
    </Spin>
  );
};

export default ChecklistUserItem;
