import { Col, Input, Row, Form, Button } from "antd";
import { useContext } from "react";
import _t from "../../lang/translate";
import { SignUpFormType } from "../../types/loginTypes";
import { SignupContext } from "./signUpProvider";
import {passwordRegex} from "../../utilities/password";


function SignupUserFormItems() {
  const { nextStep } = useContext(SignupContext)
  const userFieldsNamePaths: ['user', keyof SignUpFormType['user']][] = [
    ["user", "name"],
    ["user", "phone"],
    ["user", "password"],
    ["user", "passwordConfirmation"],
    ["user", "email"],
  ]
  return (<>
    <h2 className="mb-4 text-24 text-semibold">Opret din brugerprofil</h2>
    <Row gutter={[{ lg: 72, sm: 36, xs: 24 }, 24]}>
      <Col md={12} span={24}>
        <Form.Item label={_t("fullname")} name={["user", "name"]} rules={[{ required: true }, { whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={_t("email")} name={["user", "email"]} rules={[{ required: true }, { type: "email" }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={_t("phone")} name={["user", "phone"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          className="mt-4"
          label={_t("password")}
          name={["user", "password"]}
          rules={[
            { required: true },
            { whitespace: true },
            { min: 8, message: _t('password_min_8') },
            { pattern: passwordRegex, message: _t('password_complexity') }
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          label={_t("password-confirm")}
          name={["user", "passwordConfirmation"]}
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(["user", "password"]) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(_t("msg.password_not_match")));
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>
      </Col>

      <Col md={12} span={24} >
        <div className="signup-info-card">
          <Form.Item name={["dealer", "name"]} rules={[{ required: true }, { whitespace: true }]}>
            <Input disabled placeholder={_t("name")} />
          </Form.Item>
          <Form.Item name={["dealer", "address"]} rules={[{ required: true }]}>
            <Input placeholder={_t("address")} disabled />
          </Form.Item>
          <Form.Item name={["dealer", "zip"]} rules={[{ required: true }]}>
            <Input disabled placeholder={_t("zip")} maxLength={10} />
          </Form.Item>
          <Form.Item name={["dealer", "city"]} rules={[{ required: true }]}>
            <Input disabled placeholder={_t("city")} />
          </Form.Item>
        </div>
      </Col>
    </Row>
    <Form.Item noStyle dependencies={userFieldsNamePaths}>
      {({ validateFields }) => {
        const validate = async () => {
          try {
            await validateFields(userFieldsNamePaths)
            nextStep && nextStep();
          } catch (error) {
          }
        }
        return (
          <Button type="primary"
            onClick={validate}
            style={{ float: 'right', marginTop: 24 }}
          >
            {_t("continue")}
          </Button>
        )
      }}
    </Form.Item>
  </>
  )
}

export default SignupUserFormItems;
