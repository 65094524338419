import React, { FC, useState } from "react";
import { Button, Col, Form, Input, message, Result, Row } from "antd";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { useHistory } from "react-router-dom";
import { sendResetPasswordEmail } from "../../../services/authService";
import { ForgotPasswordType } from "../../../types/loginTypes";

const ForgotPassword: FC = () => {
  const history = useHistory();
  const [forgotPasswordForm] = Form.useForm<ForgotPasswordType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sendSucceeded, setSendSucceeded] = useState<boolean>(false);

  const handleResetPassword = async (values: ForgotPasswordType) => {
    try {
      setLoading(true);
      await sendResetPasswordEmail(values);
      setSendSucceeded(true);
    } catch ({ response }) {
      if (response?.data?.errors) {
        forgotPasswordForm.setFields(response.data.errors);
      } else {
        message.error(response?.data?.message || _t("msg.unknown_error"));
      }
    } finally {
      setLoading(false);
    }
  };

  return sendSucceeded ? (
    <Result
      status="success"
      title={_t("msg.reset_password_email_sent")}
      subTitle={_t("msg.check_spam_for_reset_password_email")}
      extra={
        <Button className="underline-style p-0" type="link" onClick={() => history.push(getPath("login"))}>
          {_t("back_to_login")}
        </Button>
      }
    />
  ) : (
    <Form form={forgotPasswordForm} onFinish={handleResetPassword} layout="vertical" requiredMark={false}>
      <Button
        className="underline-style p-0"
        type="link"
        disabled={loading}
        onClick={() => history.push(getPath("login"))}
      >
        {_t("back_to_login")}
      </Button>
      <h1>{_t("enter_your_email")}</h1>
      <Form.Item name="email" rules={[{ required: true, type: "email" }]} label={_t("email")}>
        <Input disabled={loading} prefix={<Icon name="person-outline" />} />
      </Form.Item>
      <Row align="middle" justify="space-between">
        <Col></Col>
      </Row>
      <Button disabled={loading} className="fancy-btn mt-1" htmlType="submit" type="primary" block>
        {_t("send_password_reset_email")}
      </Button>
    </Form>
  );
};

export default ForgotPassword;
