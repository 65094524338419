import React, { FC } from "react";
import { Button, Menu, message, Space, Typography } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import Icon from "../../../../assets/icons/icon";
import { LoadingOutlined } from "@ant-design/icons";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";
import { createResellerTaxInvoice, saveResellerTaxDraftInvoice } from "../../../../services/resellerTaxService";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

interface TaxInvoiceActionsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResellerTaxInvoiceActions: FC<TaxInvoiceActionsProps> = ({ setLoading }) => {
  const { resellerTaxCase, setResellerTaxCase, canSendInvoice } = ResellerTaxCaseStore.useContainer();

  const createInvoice = async () => {
    try {
      setLoading(true);
      const { data } = await createResellerTaxInvoice(resellerTaxCase.id);
      setResellerTaxCase(data);
      message.success(_t("invoice", "created"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const createInvoiceDraft = async () => {
    try {
      setLoading(true);
      const { data } = await saveResellerTaxDraftInvoice(resellerTaxCase.id);
      setResellerTaxCase(data);
      message.success(_t("draft", "created"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const invoiceIsCreated = !!resellerTaxCase.links?.invoiceLink;
  const invoiceIsGenerating = !!resellerTaxCase.invoiceStartedAt && !invoiceIsCreated;

  const getInvoiceBtnLabel = () => {
    if (invoiceIsGenerating)
      return (
        <>
          <LoadingOutlined />
          &nbsp; &nbsp;
          {_t("msg.generating_invoice")}...
        </>
      );

    if (invoiceIsCreated) return _t("create_invoice", 'again');

    return _t("create_invoice");
  };

  const invoiceMenu = (
    <Menu>
      <Menu.Item key="1" onClick={createInvoiceDraft}>
        {_t("create_invoice_draft")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Typography.Title level={5}>{_t("customer", "invoice")}</Typography.Title>
      <div className="text-right">
        <Space>
          <Button
            type="primary"
            disabled={!resellerTaxCase.links?.invoiceLink}
            ghost
            onClick={() => openFileLink(resellerTaxCase.links?.invoiceLink)}
          >
            <Icon name="download-outline" size="large" /> {_t("invoice")}
          </Button>
          <DropdownButton
            type="primary"
            onClick={createInvoice}
            disabled={invoiceIsGenerating || !canSendInvoice}
            overlay={invoiceMenu}
          >
            {getInvoiceBtnLabel()}
          </DropdownButton>
        </Space>
      </div>
    </>
  );
};

export default ResellerTaxInvoiceActions;
