import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import EnumRadio from "../../../components/form/EnumRadio";
import InputCVR from "../../../components/form/inputCVR";
import UploadButtonForm from "../../../components/form/uploadButtonForm";
import DividerLine from "../../../components/lineSplit";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";

const DealerFormFields: FC = () => {
  const { isAdmin } = DealerStore.useContainer();

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ setFieldsValue }) => (
          <Form.Item name="cvr" label={_t("cvr")} rules={[{ required: isAdmin }]} className="mb-3">
            <InputCVR disabled={!isAdmin} onFetched={setFieldsValue} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label={_t("name")} name="name" rules={[{ required: isAdmin }]}>
        <Input disabled={!isAdmin} />
      </Form.Item>
      <Form.Item label={_t("contact_person")} name="contactPerson" rules={[{ required: isAdmin }]} className="mb-3">
        <Input disabled={!isAdmin} />
      </Form.Item>
      <Form.Item label={_t("contact_person_leasing")} name="contactPersonLeasing" rules={[{ required: isAdmin }]} className="mb-3">
        <Input disabled={!isAdmin} />
      </Form.Item>

      <Form.Item label={_t("address")} name="address" rules={[{ required: isAdmin }]}>
        <Input disabled={!isAdmin} />
      </Form.Item>
      <Form.Item label={_t("zip", "city", " & ")} className="mb-3 hide-error-message" required={isAdmin}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="zip" noStyle rules={[{ required: isAdmin }]}>
              <Input maxLength={10} disabled={!isAdmin} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="city" noStyle rules={[{ required: isAdmin }]}>
              <Input disabled={!isAdmin} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label={_t("phone")} name="phone" rules={[{ required: isAdmin, whitespace: true }]}>
        <Input disabled={!isAdmin} />
      </Form.Item>
      <Form.Item label={_t("email")} name="email" rules={[{ required: isAdmin, type: "email" }]}>
        <Input disabled={!isAdmin} />
      </Form.Item>
      <Form.Item label={_t("bookKeeper", "email")} name="bookKeepingEmail" rules={[{ type: "email" }]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("reminder_email")} name="escalationEmail" rules={[{ type: "email" }]}>
        <Input />
      </Form.Item>
      <DividerLine />
      <Form.Item label={_t("logo_position")} name="logoPositionId">
        <EnumRadio nameKey={["dealer", "logoPosition"]} defaultActiveFirstOption />
      </Form.Item>
      <Form.Item label={_t("logo")} name="logoFile">
        <UploadButtonForm />
      </Form.Item>
    </>
  );
};

export default DealerFormFields;
