import { Button, Form, FormInstance, Space } from "antd";
import { FC } from "react";
import EnumRadio from "../../../../components/form/EnumRadio";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import { CustomerKeyType, CustomerType } from "../../../../types/customerTypes";
import { LeasingCaseType } from "../../../../types/leasingTypes";
import CustomerFormFields from "../../customer/customerFormFields";
import ContactPersonFormFields from "../contactPersonFormFields";

interface LeasingUpdateCustomerFormProps {
  leasingCase: LeasingCaseType;
  systemCustomer?: CustomerType | null;
  isPrivate: boolean;
  hidePBS: boolean;
  form: FormInstance;
}

const LeasingUpdateCustomerForm: FC<LeasingUpdateCustomerFormProps> = ({
  leasingCase,
  systemCustomer,
  isPrivate,
  hidePBS,
  form,
}) => {
  const { customer } = leasingCase;

  const getFieldItemExtra = (name: CustomerKeyType) => {
    const dealerValue = customer ? customer[name] : null;
    const systemValue = systemCustomer ? systemCustomer[name] : null;
    if (name === "cpr" && customer && systemValue) {
      const formValues = form.getFieldsValue();
      form.setFieldsValue({
        ...formValues,
        customer: { ...customer, cpr: systemValue.toString() },
      });
    }
    const setValue = (value: any) => {
      const formValues = form.getFieldsValue(true);
      const nextCustomer = { ...formValues?.customer, [name]: value };
      form.setFieldsValue({ ...formValues, customer: nextCustomer });
    };

    return dealerValue?.toString().trim() !== systemValue?.toString().trim() ? (
      <Space direction="horizontal" split={<DividerLine type="vertical" />}>
        {
          <span>
            Forhandler:
            {dealerValue ? (
              <Button type="link" onClick={() => setValue(dealerValue)} className="underline-style p-0 ml-05">
                {dealerValue}
              </Button>
            ) : (
              <small>{_t("no_value")}</small>
            )}
          </span>
        }
        {systemValue && (
          <span>
            System:
            {systemValue ? (
              <Button type="link" onClick={() => setValue(systemValue)} className="underline-style p-0 ml-05">
                {systemValue}
              </Button>
            ) : (
              <small>{_t("no_value")}</small>
            )}
          </span>
        )}
      </Space>
    ) : null;
  };

  return (
    <>
      <Form.Item name={["customer", "typeId"]} label={_t("type")}>
        <EnumRadio nameKey={["customer", "type"]} disabled />
      </Form.Item>
      <CustomerFormFields
        formKey="customer"
        hidePBS={hidePBS}
        isPrivate={isPrivate}
        formItemExtra={systemCustomer ? getFieldItemExtra : null}
      />
      {!isPrivate && (
        <>
          <ContactPersonFormFields formKey="legalOwner" />
          <ContactPersonFormFields formKey="contactPerson" />
        </>
      )}
    </>
  );
};

export default LeasingUpdateCustomerForm;
