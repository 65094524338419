import React, { FC, useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { login } from "../../../services/authService";
import { LocationStateType } from "../../../types/appTypes";
import { LoginType } from "../../../types/loginTypes";
import log from "../../../services/logService";
import UserStore from "../../../contexts/userStore";
import { trackKlaviyoLogin } from "../../../services/klaviyoTrackerService";

interface LoginFormProps {
  title: string;
}

const LoginForm: FC<LoginFormProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async (values: LoginType) => {
    try {
      setLoading(true);
      await login(values);
      const oldState = location.state;

      window.history.replaceState(null, "", getPath("home"));
      window.location.href = oldState?.from ? oldState?.from?.pathname : "/";
      trackKlaviyoLogin(values.email)
    } catch (error) {
      log({ from: "login page", error });
      message.error(error?.response?.data?.message || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleForgetPassword = () => {
    const path = getPath("forgotPassword");
    history.push(path);
  };

  return (
    <Form<LoginType> layout="vertical" name="loginForm" requiredMark={false} onFinish={handleLogin}>
      <h1>{title}</h1>
      <Form.Item name="email" rules={[{ required: true, type: "email" }]} label={_t("email")}>
        <Input autoFocus disabled={loading} prefix={<Icon name="person-outline" />} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true }]} label={_t("password")}>
        <Input.Password disabled={loading} prefix={<Icon name="lock-closed-outline" />} />
      </Form.Item>
      <Row align="middle" justify="space-between">
        <Col>
          <Button className="underline-style p-0" type="link" disabled={loading} onClick={handleForgetPassword}>
            {_t("forgot_password")}
          </Button>
        </Col>
      </Row>
      <Button disabled={loading} className="fancy-btn mt-1" htmlType="submit" type="primary" block>
        {_t("log_in")}
      </Button>
      <div className="text-center mt-2">
        {_t("dont_have_account")}&nbsp;
        <Button type="link" className="underline-style p-0">
          <Link to={getPath("signUp")}>{_t("create_account")}</Link>
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
