import React, { FC } from "react";
import { Button, Col, message, Modal, Row, Space, Typography } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import Icon from "../../../../assets/icons/icon";
import DividerLine from "../../../../components/lineSplit";
import UploadButton from "../../../../components/uploadButton";
import UploadFileRender from "../../../../components/uploadFileRender";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { deleteFile, openFileLink } from "../../../../services/mediaService";
import { sentTaxReceipt, taxLegalFileUrl } from "../../../../services/taxService";
import useIsDraggingFiles from "../../../../hooks/useIsDraggingFiles";
import DragUpload from "../../../../components/dragUpload";
import { accepted } from "../../../../utilities/acceptedFiles";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import PopoverList from "../../../../components/popups/popoverList";
import { TaxCase } from "../../../../types/taxTypes";

const { confirm } = Modal;

interface TaxReceiptActionsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxReceiptActions: FC<TaxReceiptActionsProps> = ({ setLoading }) => {
  const [isDragging] = useIsDraggingFiles();
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const { legalFiles } = taxCase;
  const action = taxLegalFileUrl(taxCase.id);

  const { cannotSendReceipt, rejectReasons } = checkCanSendReceipt(taxCase)

  const handleSendReceipt = async () => {
    try {
      setLoading(true);
      const { data } = await sentTaxReceipt(taxCase.id);
      setTaxCase(data);
      message.success("Kvittering Sent");
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleFileDelete = async (file: UploadFile) => {
    const serverId = file.response?.id;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        setLoading(true);
        await deleteFile(action, serverId);
      } catch (error) {
        renderErrorMessage(error)
        canRemove = false;
      } finally {
        setLoading(false);
      }
    }
    if (canRemove) {
      setTaxCase({ ...taxCase, legalFiles: null });
    }
  };

  const handleUploadChange = ({ file, fileList }: any) => {
    setTaxCase({ ...taxCase, legalFiles: fileList });
  };

  const props = {
    disabled: !!legalFiles?.length && legalFiles[0].status === "done",
    fileList: legalFiles || [],
    accept: accepted.join(","),
    action: taxLegalFileUrl(taxCase.id),
    onChange: handleUploadChange,
  };

  return (
    <div className="position-relative">
      <Typography.Title className="mb-05" level={5}>
        {_t("receipt")}
      </Typography.Title>

      {!!taxCase.legalFiles?.length && (
        <>
          <UploadFileRender onRemove={handleFileDelete} hideDownload file={taxCase.legalFiles[0]} loading={false} />
          <DividerLine className="mt-05 mb-05" />
        </>
      )}
      <DragUpload
        className={`${isDragging && !props.disabled ? "is-dragging" : ""} file-drop-zone`}
        showUploadList={false}
        {...props}
      >
        <Typography.Title level={3} className="mb-0">
          {_t("msg.drop_receipt_her")}
        </Typography.Title>
      </DragUpload>
      <Row justify="space-between" gutter={8} align="middle" className="mt-1">
        <Col>
          <UploadButton {...props} />
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              disabled={!taxCase.legalFiles?.length}
              ghost
              onClick={() => taxCase.legalFiles && openFileLink(taxCase.legalFiles[0].response?.url)}
            >
              <Icon name="download-outline" size="large" /> {_t("receipt")}
            </Button>
            <PopoverList
              title={_t("msg.needs_input")}
              placement="topRight"
              trigger={cannotSendReceipt ? "hover" : "none"}
              list={rejectReasons}
            >
              <Button
                disabled={cannotSendReceipt}
                type="primary"
                onClick={() => {
                  confirm({ content: _t("msg.confirm_send_receipt"), onOk: handleSendReceipt });
                }}
              >
                {_t("send_receipt")}
              </Button>
            </PopoverList>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default TaxReceiptActions;

function checkCanSendReceipt(taxCase: TaxCase) {
  const hasReceipt = !!taxCase.legalFiles?.length;

  const isGeneratingInvoice = !!taxCase.invoiceStartedAt;
  const hasInvoiceLink = !!taxCase.links?.invoiceLink;
  const cannotSendReceipt = !(hasReceipt && (hasInvoiceLink || isGeneratingInvoice));

  const rejectReasons = [];
  if (!hasReceipt) {
    rejectReasons.push(_t("msg.needs_receipt"));
  }
  if (!isGeneratingInvoice && !hasInvoiceLink) {
    rejectReasons.push(_t("msg.needs_invoice_sent"));
  }

  return {
    rejectReasons, cannotSendReceipt
  }
}
