import { Layout } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import webSocket from "../services/webSocket";
import notifi from "../components/notifi";
import { isNotificationLevel } from "../types/notificationsType";
import AppFooter from "../layout/appFooter";
import AppHeader from "../layout/appHeader";
import AppSider from "../layout/appSider";
import { isFullscreenRoute } from "../routes/appRoutes";
import UserStore from "../contexts/userStore";
import { useInterval } from "../hooks/useInterval";
import AppExtra from "../layout/appExtra";
import { initializeIntercomService, updateIntercomService } from "../services/intercomService";

const { Content } = Layout;

const AppInit = () => {
  const location = useLocation();
  const { user, fetchUserData } = UserStore.useContainer();
  const [intercomInitialized, setIntercomInitialized] = useState<boolean>(false);

  useInterval(fetchUserData, 2 * 60 * 1000);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user && !intercomInitialized) {
      setIntercomInitialized(true);
      initializeIntercomService(user);
    }
  }, [user])

  useEffect(() => {
    // Update Intercom Messenger Service every time the view/location changes.
    updateIntercomService()
  }, [location])

  return null;
}

const AuthorizedApp: FC = ({ children }) => {
  const location = useLocation();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isEmbedded, setIsEmbedded] = useState<boolean>(true);

  // This should be in notification store that would also cache it.
  const notify = (data: any) => {
    const { level, message, description, id } = data;
    const iconType = isNotificationLevel(level) ? level : "default";
    notifi[iconType]({
      key: id,
      message,
      description,
      placement: "topRight",
      top: 90,
      duration: 3,
    });
  };

  useEffect(() => {
    const socket = webSocket.create;
    webSocket.connectToNotification(socket, notify);
    return () => {
      webSocket.disconnect(socket);
    };
  }, []);

  useEffect(() => {
    setIsEmbedded(window.self !== window.top);
  }, []);

  useEffect(() => {

    setIsFullscreen(isFullscreenRoute(location.pathname));
    window.parent.postMessage({type: 'url-change', path: location.pathname}, "*");

  }, [location]);

  const pageClassName = location.pathname.split("/")[1] || "";

  return (
    <Layout className={`app-wrapper ${isEmbedded ? "is-embedded" : ""}`}>
      <AppSider />
      <Layout className="site-layout">
        <AppHeader />
        <Content className={`content${isFullscreen ? " content-fluid" : ""} ${pageClassName}`}>{children}</Content>
        <AppFooter />
        <AppExtra />
        <AppInit />
      </Layout>
    </Layout>
  );
};

export default AuthorizedApp;
