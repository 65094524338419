import { createContext, FC, useEffect, useState } from "react";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import { subscriptionPackages } from "../../services/subcriptionService";
import { SubscriptionPackageType, SubscriptionPriceChangeType } from "../../types/subscriptionTypes";





type SignupContextType = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    nextStep: () => void;
    prevStep: () => void;
    currentStep: number;
    packages: SubscriptionPackageType[] | null;
    chosenSubscriptionPackage: SubscriptionPackageType | null;
    setChosenSubscriptionPackage: React.Dispatch<React.SetStateAction<SubscriptionPackageType | null>>;
    subscriptionPrice: null | SubscriptionPriceChangeType,
    setSubscriptionPrice: React.Dispatch<React.SetStateAction<SubscriptionPriceChangeType | null>>;
    usingCard: boolean;
    setUsingCard: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SignupContext = createContext<SignupContextType>({
    loading: false,
    setLoading: () => undefined,
    nextStep: () => undefined,
    prevStep: () => undefined,
    currentStep: 0,
    packages: null,
    chosenSubscriptionPackage: null,
    setChosenSubscriptionPackage: () => undefined,
    subscriptionPrice: null,
    setSubscriptionPrice: () => undefined,
    usingCard: true,
    setUsingCard: () => undefined,
});


const SignUpProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [packages, setPackages] = useState<SubscriptionPackageType[] | null>(null)
    const [chosenPackage, setChosenPackage] = useState<SubscriptionPackageType | null>(null)
    const [currentStep, setCurrentStep] = useState(0);
    const [subscriptionPrice, setSubscriptionPrice] = useState<null | SubscriptionPriceChangeType>(null);
    const [usingCard, setUsingCard] = useState(true);

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);


    const signupContext: SignupContextType = {
        loading,
        setLoading,
        nextStep,
        prevStep,
        currentStep,
        packages,
        chosenSubscriptionPackage: chosenPackage,
        setChosenSubscriptionPackage: setChosenPackage,
        subscriptionPrice,
        setSubscriptionPrice,
        usingCard,
        setUsingCard,
    }

    useEffect(() => {
        const getPackages = async () => {
            setLoading && setLoading(true)
            try {
                const { data } = await subscriptionPackages()
                setPackages(data.data.sort((a, b) => a.level - b.level))
            } catch (error) {
                renderErrorMessage(error)
            } finally {
                setLoading && setLoading(false)
            }
        }
        getPackages();
    }, [])

    return (
        <SignupContext.Provider value={signupContext}>
            {children}
        </SignupContext.Provider>
    )
}
export default SignUpProvider;
