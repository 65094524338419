import { AutoComplete, Button, Col, Form, Input, Modal, Row } from "antd";
import React, { FC, useState } from "react";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import NewPriceEquipments from "./newPriceEquipments";
import TechnicalNewPrice from "./technicalNewPrice";
import Icon from "../../../../assets/icons/icon";
import VehicleCatalog from "../../../vehicleCatalog/vehicleCatalog";
import TaxCaseStore from "../../../../contexts/taxCaseStore";

const sourceOptions = [
  { value: "Estimatic" },
  { value: "Kilde til basisnypris er Bilinfo - Modelkatalog. Priser på ekstraudstyr er skønnet på baggrund af liste fra DAT.de (importør-anmeldt pris), hvor vi under hensyntagen til moms og afgift har foretaget en omberegning fra Euro til DKK. (Beregning: Europris * 7,5 * 2). Omregnet pris på ekstraudstyret er holdt op imod andre importør-anmeldte priser i Danmark, hvorfor denne omberegning skønnes for værende både retvisende og markedsdannende." },
  { value: "Kilde til nypris er www.motorregister.skat.dk -> Registreringsafgift -> Vis køretøjsvurderinger. Vi har taget udgangspunkt i køretøjets genberegnet, tekniske nypris, som skønnes for retvisende og markedsdannende i forhold til udsalgspriser på aktuelle køretøj. Priser på ekstraudstyr er skønnet på baggrund af liste fra DAT.de (importør-anmeldte priser), hvor vi under hensyntagen til moms og afgift har foretaget en omberegning fra Euro til DKK. (Beregning: Europris * 7,5 * 2). Omregnet pris på ekstraudstyret er holdt op imod andre importør-anmeldte priser i Danmark, hvorfor denne omberegning skønnes for værende både retvisende og markedsdannende." },
]

const NewPriceFinder: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const [isPriceFinder, setIsPriceFinder] = useState<boolean>(false);

  const showPriceFinder = () => setIsPriceFinder(true);
  const hidePriceFinder = () => setIsPriceFinder(false);

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {({ setFieldsValue }) => (
          <>
            <Button type="link" icon={<Icon name="open-outline" />} onClick={showPriceFinder} />

            <Modal width={"70%"}
              title={_t('catalog')}
              visible={isPriceFinder} footer={null} onCancel={hidePriceFinder}>
              <VehicleCatalog
                currentTab="variant"
                withActions={false}
                priceSelectAction={(val) => {
                  setFieldsValue({ "newPrice": { "newPriceWithoutEquipments": val } });
                  hidePriceFinder();
                }}
                initialVin={taxCase.vehicle?.vin || ''}
              />
            </Modal>
          </>
        )}
      </Form.Item>
    </>
  )
}

const NewPriceFormItems: FC = () => {
  return (
    <>
      <Form.Item
        label={_t("new_price", "without_equipments")}
      >

        <Row>
          <Col>
            <NewPriceFinder />
          </Col>

          <Col flex="auto">
            <Form.Item
              name={["newPrice", "newPriceWithoutEquipments"]}
              noStyle>
              <InputNum min={0} suffix="kr." />
            </Form.Item>
          </Col>
        </Row>

      </Form.Item>
      <Form.Item name={["newPrice", "newPriceSource"]} label={`${_t("source")} - ${_t("note")}`}
        className="mb-2"
      >
        <AutoComplete
          options={sourceOptions}
        >
          <Input.TextArea showCount={{ formatter: ({ count }) => `${count} / 1024` }} maxLength={1024} />
        </AutoComplete>
      </Form.Item>
      <TechnicalNewPrice />
      <NewPriceEquipments />
    </>
  );
};

export default NewPriceFormItems;
