import { Button, Modal } from "antd";
import React, { FC, useState } from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { EquipmentCalculatorType } from "../../../../types/taxDocTypes";
import NewPriceEquipmentsForm from "./newPriceEquipmentsForm";

interface NewPriceEquipmentsListProps {
  value?: EquipmentCalculatorType | null;
  onChange?: (value: EquipmentCalculatorType | null) => void;
}

const NewPriceEquipmentsList: FC<NewPriceEquipmentsListProps> = ({
  value,
  onChange,
}) => {
  const { taxCase } = TaxCaseStore.useContainer();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleApply = (v: { equipmentCalculator: EquipmentCalculatorType }) => {
    onChange && onChange(v.equipmentCalculator);
    closeModal();
  };

  return (
    <>
      <Button type="link" className="pl-0 pr-0 m-0" onClick={openModal}>
        {_t("edit")}
      </Button>
      <Modal
        width={1161}
        title={null}
        visible={isModalVisible}
        destroyOnClose
        closable
        onCancel={closeModal}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <NewPriceEquipmentsForm
          onApply={handleApply}
          vin={taxCase.vehicle?.vin}
          initialValues={value}
        />
      </Modal>
    </>
  );
};

export default NewPriceEquipmentsList;
