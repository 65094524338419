import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import { FC, useState } from "react";
import DateSelect from "../../../../components/form/dateSelect";
import InputCVR from "../../../../components/form/inputCVR";
import InputNum from "../../../../components/form/inputNum";
import SwitchBox from "../../../../components/form/switchBox";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { updateDealExpireSettings } from "../../../../services/leasingService";
import { LeasingAdminDealExpireType } from "../../../../types/leasingTypes";
import consts from "../../../../utilities/consts";
import { isAxiosError } from "../../../../utilities/typeGuard";
import LeasingPeriodTaxField from "./leasingPeriodTaxField";

interface LeasingAdminDealExpireEditProps {
  stopEditing: () => void;
}

const LeasingAdminDealExpireEdit: FC<LeasingAdminDealExpireEditProps> = ({ stopEditing }) => {
  const { leasingCase, adminValues, setAdminValues } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async (values: LeasingAdminDealExpireType) => {
    try {
      setLoading(true);
      const { data } = await updateDealExpireSettings(leasingCase.id, values);
      if (adminValues && data) {
        setAdminValues({ ...adminValues, ...data });
      }
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form<LeasingAdminDealExpireType>
        {...consts.formItemProps}
        className="m-0"
        initialValues={{
          ...adminValues?.deal?.expireSettings,
        }}
        requiredMark={false}
        onFinish={handleSave}
      >
        <Form.Item name="unsubscribedAt" label={_t("unsubscribed_at")}>
          <DateSelect />
        </Form.Item>
        <Form.Item name="endDateDmr" label={_t("end_date_dmr")}>
          <DateSelect />
        </Form.Item>
        <Form.Item name="valueLossReference" label={_t("value_loss_reference")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="interruptionPeriodTax" label={_t("interruption_period_tax")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const values = getFieldsValue() as LeasingAdminDealExpireType;
            const { valueLossReference, interruptionPeriodTax } = values;
            const periodTax = adminValues?.deal?.activeSettings.periodTax || null;
            return (
              <Form.Item name="finalPeriodTax" label={_t("final_period_tax")}>
                <LeasingPeriodTaxField {...{ valueLossReference, interruptionPeriodTax, periodTax }} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name="soldToCvr" label={_t("sold_to_cvr")}>
          <InputCVR />
        </Form.Item>
        <Form.Item name="soldToCvrAmount" label={_t("sold_to_cvr_amount")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="inStock" label={_t("in_stock")}>
          <SwitchBox />
        </Form.Item>
        <Form.Item name="finalRestValue" label={_t("final_rest_value")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="expirationNote" label={_t("note")}>
          <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ submit }) => (
            <Row justify="space-between">
              <Col>
                <Button disabled={loading} onClick={stopEditing}>
                  {_t("cancel")}
                </Button>
              </Col>
              <Col>
                <Button loading={loading} type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default LeasingAdminDealExpireEdit;
