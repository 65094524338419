import { Button } from "antd";
import { FC } from "react";
import Details from "../../../../components/detailsList";
import LogText from "../../../../components/logText";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

interface LeasingAdminDealExpireInfoProps {
  startEditing?: () => void;
}

const LeasingAdminDealExpireInfo: FC<LeasingAdminDealExpireInfoProps> = ({ startEditing }) => {
  const { adminValues } = LeasingStore.useContainer();
  const {
    endDateDmr,
    expirationNote,
    finalPeriodTax,
    finalRestValue,
    inStock,
    interruptionPeriodTax,
    soldToCvr,
    soldToCvrAmount,
    unsubscribedAt,
    valueLossReference,
    log,
  } = adminValues?.deal?.expireSettings || {};

  const logExtraProps = {
    small: true,
    className: "p-0",
  };

  return (
    <>
      <Details borderLess>
        <Details.Item
          label={_t("unsubscribed_at")}
          value={format.date(unsubscribedAt)}
          extra={<LogText logData={log?.unsubscribedAt} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("end_date_dmr")}
          value={format.date(endDateDmr)}
          extra={<LogText logData={log?.endDateDmr} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("value_loss_reference")}
          value={format.price(valueLossReference)}
          extra={<LogText logData={log?.valueLossReference} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("interruption_period_tax")}
          value={format.price(interruptionPeriodTax)}
          extra={<LogText logData={log?.interruptionPeriodTax} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("final_period_tax")}
          value={format.price(finalPeriodTax)}
          extra={<LogText logData={log?.finalPeriodTax} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("sold_to_cvr")}
          value={format.text(soldToCvr)}
          extra={<LogText logData={log?.soldToCvr} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("sold_to_cvr_amount")}
          value={format.price(soldToCvrAmount)}
          extra={<LogText logData={log?.soldToCvrAmount} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("in_stock")}
          value={format.yesOrNo(inStock)}
          extra={<LogText logData={log?.inStock} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("final_rest_value")}
          value={format.price(finalRestValue)}
          extra={<LogText logData={log?.finalRestValue} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("note")}
          value={format.text(expirationNote)}
          extra={<LogText logData={log?.expirationNote} {...logExtraProps} />}
        />
      </Details>
      {startEditing && (
        <div className="text-right">
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </div>
      )}
    </>
  );
};

export default LeasingAdminDealExpireInfo;
