import React, { FC, useState } from "react";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { LeasingCasePaymentAdviceRecipientType, LeasingCaseType } from "../../../types/leasingTypes";
import DividerLine from "../../../components/lineSplit";
import SystemDataFetcher from "../../../components/form/systemDataFetcher";
import { CustomerType } from "../../../types/customerTypes";
import { DealerType } from "../../../types/dealerTypes";
import DateSelect from "../../../components/form/dateSelect";
import { isAxiosError } from "../../../utilities/typeGuard";
import { generatePaymentAdviceRestValuePreview, sendPaymentAdviceRestValueEmail } from "../../../services/leasingService";
import InputCVR from "../../../components/form/inputCVR";
import { SelectableFetchType } from "../../../types/miscTypes";
import { CVRData } from "../../../types/appTypes";
import Icon from "../../../assets/icons/icon";

interface LeasingPaymentAdviceFormProps {
  onCvrFetch: (data: CVRData) => void,
  leasingCase: LeasingCaseType
}

const LeasingPaymentAdviceForm: FC<LeasingPaymentAdviceFormProps> = ({ onCvrFetch, leasingCase }) => {
  return (
    <>
      <DividerLine fluid />

      <Form.Item name="cvr" label={_t("cvr")} rules={[{ required: false }]}>
        <InputCVR onFetched={onCvrFetch} />
      </Form.Item>

      <Form.Item
        name={"name"}
        rules={[{ required: true }]}
        label={_t('name')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"address"}
        rules={[{ required: true }]}
        label={_t('address')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"zip"}
        rules={[{ required: true }]}
        label={_t('zip')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"city"}
        rules={[{ required: true }]}
        label={_t('city')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"email"}
        rules={[{ required: true, type: 'email' }]}
        label={_t('email')}
      >
        <Input />
      </Form.Item>

      <DividerLine fluid />

      <Form.Item shouldUpdate noStyle>
        {({ setFieldsValue }) => (
          <Form.Item
            name={"priceWithoutTaxes"}
            rules={[{ required: true }]}
            label={_t('final_rest_value_no_tax')}
          >
            <Input
              className="input-group-btn"
              addonAfter={(
                <Button
                  className="p-0 m-0"
                  onClick={() => setFieldsValue({ priceWithoutTaxes: leasingCase.deal.output.restValue.totalNoVat })}
                  type="link"
                  icon={<Icon name="refresh-outline" />}
                />
              )} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        name={"isVatDeadVehicle"}
        rules={[{ required: false }]}
        label={_t('vat_dead_vehicle')}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        name={"paymentDate"}
        rules={[{ required: true }]}
        label={_t('due_date')}
      >
        <DateSelect />
      </Form.Item>
    </>
  );
};

const LeasingGeneratePaymentAdviceRestValueDocument: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [paymentAdviceForm] = Form.useForm<LeasingCasePaymentAdviceRecipientType>();
  const { paymentAdviceRecipient } = leasingCase;

  const closeModal = () => setShowModal(false)

  const handleSubmit = async () => {
    const values: LeasingCasePaymentAdviceRecipientType = paymentAdviceForm.getFieldsValue(true);

    try {
      setLoading(true);
      await sendPaymentAdviceRestValueEmail(leasingCase.id, values);
      closeModal();
      message.success(_t("sent"));
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const disabled = !leasingCase.flags.validationStarted;

  const handleSystemDataFetch = (type: SelectableFetchType, data: CustomerType | DealerType) => {
    let name = data.name;
    if (!name) {
      name = (data as any).nameFirst || '';
      name = name + ' ';
      name = name + (data as any).nameLast || '';
    }
    const { cvr, address, zip, city, email } = data;

    paymentAdviceForm.setFieldsValue({ cvr: cvr ? String(cvr) : null, address, zip, city, email, name });
  }

  const handlePreview = async () => {
    const values = paymentAdviceForm.getFieldsValue();

    try {
      setLoading(true);
      const { data } = await generatePaymentAdviceRestValuePreview(leasingCase.id, values);
      if (data.url) window.open(data.url);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button loading={loading} disabled={disabled} onClick={() => setShowModal(true)}>
        {_t("paymentAdviceDocumentRestValue")}
      </Button>

      <Modal
        confirmLoading={loading}
        width={800}
        visible={showModal}
        onCancel={closeModal}
        title={_t("paymentAdviceDocumentRestValue")}
        okText={_t("send", 'paymentAdviceDocumentRestValue')}
        onOk={paymentAdviceForm.submit}
      >
        <SystemDataFetcher wantedTypes={['private', 'company', 'dealer']} onFetch={handleSystemDataFetch} />

        <Form
          form={paymentAdviceForm}
          requiredMark={true}
          onFinish={handleSubmit}
          initialValues={{ ...paymentAdviceRecipient }}
        >
          <LeasingPaymentAdviceForm
            onCvrFetch={(data: any) => handleSystemDataFetch('dealer', data)}
            leasingCase={leasingCase}
          />
        </Form>

        <Row>
          <Col span={24} className="text-right pt-1">
            <Button type="link" size="small" onClick={handlePreview}>
              {_t('preview')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default LeasingGeneratePaymentAdviceRestValueDocument;
