import React, { FC } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { getPath, routes } from "./appRoutes";
import { homePath } from "./../config.json";
import ProtectedRoute from "./protectedRoute";
import Screen from "../components/screen";
import UserStore from "../contexts/userStore";
import { getCurrentUser } from "../services/authService";

const Routes: FC = () => {
  const { hasPermission } = UserStore.useContainer();
  const location = useLocation();
  const hasUser = getCurrentUser();
  return (
    <Switch>
      {routes.map((route) => {
        const Comp = route.component;
        return route.isPublicRoute ? (
          <Route
            key={route.path}
            path={route.path}
            render={(props) => (
              <Screen>
                <Comp title={route.title} {...props} />
              </Screen>
            )}
          />
        ) : (
          hasPermission(route.permission) && (
            <ProtectedRoute key={route.path} path={route.path} component={Comp} extraProps={{ title: route.title }} />
          )
        );
      })}
      <Redirect from="/" to={hasUser ? homePath : getPath("login")} exact />
      <Redirect
        to={{
          pathname: hasUser ? "/404" : getPath("login"),
          state: { from: { pathname: location.pathname } },
        }}
      />
    </Switch>
  );
};

export default Routes;
