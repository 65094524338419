import { RegFeeCalcInputType, RegFeeCalcOutputType } from './regFeeCalculatorIndex';
import {
    calculateRegistrationFee as calculate,
    AmendedPrivateInput,
    AmendedVanInput,
    AmendedMotorcycleInput,
    PrivateOutput,
    VanOutput,
    MotorcycleOutput
} from '@dmf/calculator-library';

export const mapEmissionUnit = (
    emissionUnitID: RegFeeCalcInputType['emissionUnitId']
): AmendedPrivateInput['emissionUnit'] & AmendedVanInput['emissionUnit'] => {
    switch (emissionUnitID) {
        case 1: return "NEDC";
        case 3: return "NEDC2";
        default: return "WLTP"; // ID === 2
    }
}
const mapEnergyType = (
    energyType: PrivateOutput['config']['energyType'] | VanOutput['config']['energyType']
): RegFeeCalcOutputType['energyType'] => {
    switch (energyType) {
        case 'Zero emission': return {
            id: 3,
            title: "Nul emissionsbil",
        };
        case 'Low emission': return {
            id: 2,
            title: 'Lav emissionsbil'
        };
        case 'High emission': return {
            id: 1,
            title: 'Høj emissionsbil'
        };
    }
}

const getCalculator = (
    vehicleTypeId: RegFeeCalcInputType['vehicleTypeId']
) => {
    switch (vehicleTypeId) {
        case 1: return calculatePrivate;
        case 2: return calculateVan;
        case 3: return calculateMotorCycle;
        default: return calculatePrivate;
    }
}

const mapIsNewRelatedInputs = (input: RegFeeCalcInputType) => {
    return input.isNew ? {
        taxableValue: input.taxablePrice || 0,
        newPrice: null,
        tradePrice: null,
    } : {
        newPrice: input.newPrice || 0,
        tradePrice: input.tradePrice || 0,
        taxableValue: null,
    };
}

const calculatePrivate = (input: RegFeeCalcInputType): PrivateOutput => {
    const privateInput: AmendedPrivateInput = {
        vehicleType: "PRIVATE_VEHICLE",
        electricConsumption: input.electricConsumption || 0,
        electricRange: input.electricRange || 0,
        batteryCapacity: input.batteryCapacity || 0,
        emissionUnit: mapEmissionUnit(input.emissionUnitId),
        emissionValue: input.emissionAmount || 0,
        isNew: input.isNew,
        versionYear: new Date().getFullYear() as AmendedPrivateInput['versionYear'],
        ...mapIsNewRelatedInputs(input),
    }
    return calculate(privateInput)
}
const calculateVan = (input: RegFeeCalcInputType): VanOutput => {
    const vanInput: AmendedVanInput = {
        vehicleType: "VAN",
        versionYear: new Date().getFullYear() as AmendedPrivateInput['versionYear'],
        isHighWeight: input.isHighWeight || false,
        electricConsumption: input.electricConsumption || 0,
        electricRange: input.electricRange || 0,
        batteryCapacity: input.batteryCapacity || 0,
        emissionUnit: mapEmissionUnit(input.emissionUnitId),
        emissionValue: input.emissionAmount || 0,
        isNew: input.isNew,
        ...mapIsNewRelatedInputs(input),
    }
    return calculate(vanInput)
}

const calculateMotorCycle = (input: RegFeeCalcInputType): MotorcycleOutput => {
    const mcInput: AmendedMotorcycleInput = {
        vehicleType: "MOTORCYCLE",
        isElectric:
            input.emissionAmount === 0 ||
            input.emissionAmount === null ||
            input.emissionAmount === undefined,
        versionYear: new Date().getFullYear() as AmendedPrivateInput['versionYear'],
        isNew: input.isNew,
        ...mapIsNewRelatedInputs(input),
    }
    return calculate(mcInput)
}


const mapPrivateOrVanOutput = (
    output: PrivateOutput | VanOutput,
    input: RegFeeCalcInputType
): RegFeeCalcOutputType => {
    const mappedOutput = {
        consts: {
            emission1Max: output.config.emissionRates?.[0]?.limit || 0,
            emission2Max: output.config.emissionRates?.[1]?.limit || 0,
            scaleOneMax: output.config.scaleBreakRates?.[0]?.limit || 0,
            scaleTwoMax: output.config.scaleBreakRates?.[1]?.limit || 0,
        },
        scaleThreeAmount: output.result.scaleBreakTax.taxes?.[2] || 0,
        emissions: [
            output.config.emissionTax.taxes?.[0] || 0,
            output.config.emissionTax.taxes?.[1] || 0,
            output.config.emissionTax.taxes?.[2] || 0,
        ],
        vehicleTypeId: input.vehicleTypeId || 1,
        energyType: mapEnergyType(output.config.energyType),
        emission: output.config.emission,
        batteryCapacity: output.config.batteryCapacity,
        valueLoss: output.config.valueLoss,
        batteryDeductionAmount: output.config.batteryDeduction,
        grossTax: output.result.scaleBreakTax.sum,
        emissionAddon: output.config.emissionTax.sum,
        bottomDeductionStandardAmount: output.config.baseDeduction,
        taxDeductionAmount: output.result.taxPhasingAmount,
        lowDeductionAmount:
            output.config.baseDeductionElectric +
            output.config.baseDeductionHybrid,
        taxablePrice: output.result.taxableValue,
        registrationFeeBeforeDeduction: output.result.taxBeforeDeduction,
        registrationFee: output.result.taxAfterAllDeductions,
        scales: output.result.scaleBreakTax.taxes,
    }

    return mappedOutput
}

const mapMotorcycleOutput = (
    output: MotorcycleOutput,
    input: RegFeeCalcInputType
): RegFeeCalcOutputType => {
    return {
        consts: {
            emission1Max: 0,
            emission2Max: 0,
            scaleOneMax: output.config.scaleBreakRates?.[0]?.limit || 0,
            scaleTwoMax: output.config.scaleBreakRates?.[1]?.limit || 0,
        },
        emissions: [],
        scaleThreeAmount: output.result.scaleBreakTax.taxes?.[2] || 0,
        vehicleTypeId: input.vehicleTypeId || 3,
        energyType: mapEnergyType(output.config.energyType),
        emission: null,
        batteryCapacity: null,
        valueLoss: output.config.valueLoss,
        batteryDeductionAmount: null,
        grossTax: output.result.scaleBreakTax.sum,
        emissionAddon: null,
        bottomDeductionStandardAmount: null,
        taxDeductionAmount: output.result.taxPhasingAmount,
        lowDeductionAmount: output.config.baseDeductionElectric,
        taxablePrice: output.result.taxableValue,
        registrationFeeBeforeDeduction: output.result.taxBeforeDeduction,
        registrationFee: output.result.taxAfterAllDeductions,
        scales: output.result.scaleBreakTax.taxes,
    }
}

const mapOutput = (
    output: MotorcycleOutput | PrivateOutput | VanOutput,
    input: RegFeeCalcInputType
): RegFeeCalcOutputType => {
    switch (input.vehicleTypeId) {
        case 1: return mapPrivateOrVanOutput(output as PrivateOutput, input);
        case 2: return mapPrivateOrVanOutput(output as VanOutput, input);
        case 3: return mapMotorcycleOutput(output as MotorcycleOutput, input);
        default: return mapPrivateOrVanOutput(output as PrivateOutput, input);
    }
}

const calculateRegistrationFee = async (input: RegFeeCalcInputType): Promise<RegFeeCalcOutputType> => {
    const calculator = getCalculator(input.vehicleTypeId);
    const output: ReturnType<typeof calculator> = await new Promise((resolve, reject) => {
        resolve(calculator(input));
    })
    return mapOutput(output, input)
}

export default calculateRegistrationFee;
