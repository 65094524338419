import React, { useEffect, useContext, useState } from "react";
import _t from "../../lang/translate";
import { Button, Form, Input, Layout, Steps } from "antd";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/DMF_vandret_logo_tagline1_NEG.png";
import DashboardImg from '../../assets/images/dashboard-car.png'
import { getPath } from "../../routes/appRoutes";
import { SignUpFormType, SignUpType } from "../../types/loginTypes";
import { getCurrentUser, signUp } from "../../services/authService";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import Icon from "../../assets/icons/icon";
import LoadingContainer from "../../components/loadingContainer";
import { initSignupPaymentMethodIntent } from "../../services/subcriptionService";

import SignupUserFormItems from "./signupUserFormItems";
import SignupDealerFormItems from "./signupDealerFormItems";
import SignupLandingView from "./signupLandingView";
import SignupSubscriptionPackages from "./signupSubscriptionPackages";
import SignupCardPaymenSubmit from "./signupCardPaymenSubmit";
import SignUpProvider, { SignupContext } from "./signUpProvider";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import Loading from "../../components/loading";
import { loadStripe, SetupIntent } from "@stripe/stripe-js";
import log from "../../services/logService";
import { isAxiosError } from "../../utilities/typeGuard";


const { Step } = Steps;

type SignUpStepsProps = {
    userEmail: string;
    token: string;
}

const SignUpSteps = ({ userEmail, token }: SignUpStepsProps) => {
    const { currentStep, loading, setLoading, prevStep, chosenSubscriptionPackage, usingCard } = useContext(SignupContext);
    const history = useHistory();
    const [signupForm] = Form.useForm<SignUpFormType>();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const user = getCurrentUser();
        if (user !== null) history.push(getPath("home"));
    }, [history]);


    const handleSignUp = async (values: SignUpFormType) => {
        try {
            setLoading(true);
            //@ts-expect-error
            if (values?.address !== undefined) {
                // Honeypot triggered. Its a bot
                throw new Error(_t("msg.unknown_error"))
            }

            const { setupIntent } = await handlePaymentCodeResolve() || {};
            const signupObject = getSignupTypeObj(values, setupIntent);
            await signUp(signupObject);
            window.location.href = getPath("home");
        } catch (error) {
            if (isAxiosError(error)) {
                const formErrors = error.response?.data?.errors as any[];
                if (formErrors) {
                    signupForm.setFields(formErrors);
                }
                log({ from: "Sign up", error });
            }
            renderErrorMessage(error)
        } finally {
            setLoading(false);
        }
    };

    const handlePaymentCodeResolve = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!elements.getElement("payment") || !usingCard) {
            return;
        }

        const result = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements: elements,
            redirect: "if_required",
            confirmParams: {
                return_url: window.location.href
            },
        });

        if (result.error) {
            throw new Error(result.error.message);
        } else {
            return result;
        }
    };

    function getSignupTypeObj(values: SignUpFormType, setupIntent: SetupIntent | undefined): SignUpType {
        const paymentMethodId = typeof setupIntent?.payment_method === 'string'
            ? setupIntent?.payment_method
            : "";
        return {
            dealer: values.dealer,
            user: values.user,
            subscription: {
                packageId: chosenSubscriptionPackage?.id,
                termsAccepted: values.termsAccepted,
                paymentMethodId: paymentMethodId
            },
            token,
        }
    }

    const handleFormChange = (changedValues: Partial<SignUpFormType>, values: SignUpFormType) => {
        if (changedValues.dealer?.cvr) {
            signupForm.setFieldsValue({
                ...values,
                dealer: {
                    ...values.dealer,
                    address: "",
                    zip: '',
                    city: "",
                    name: "",
                }
            })
        }
    }

    return (
        <>
            <Steps current={currentStep} responsive className="steps-title-above mb-2" labelPlacement="vertical" size="small" progressDot>
                <Step key={0} stepIndex={0} title="Velkommen" />
                <Step key={1} stepIndex={1} title="Din virksomhed" />
                <Step key={2} stepIndex={2} title="Din brugerprofil" />
                <Step key={3} stepIndex={3} title="Vælg abonnement" />
                <Step key={4} stepIndex={4} title="Godkend" />
            </Steps>

            <div className={currentStep > 0 ? "signup-card" : ""}>
                <LoadingContainer loading={loading} showLogo>
                    {currentStep > 0 && (
                        <Button type="text"
                            icon={<Icon className="text-blue" name="arrow-back-outline" />}
                            onClick={() => prevStep()}
                            className="mb-3"
                            style={{ marginLeft: -18, alignSelf: "start" }}
                        >
                            {_t("go_back")}
                        </Button>
                    )}
                    <Form<SignUpFormType>
                        form={signupForm}
                        onFinish={handleSignUp}
                        layout="vertical"
                        style={{ alignSelf: "stretch" }}
                        initialValues={{ user: { email: userEmail } }}
                        onValuesChange={handleFormChange}

                    >
                        {/* {steps[currentStep]} */}
                        {/* This is a bit wonky, but if they are not rendered,
                        then the Form.Item values dont get passed along when calling Form.submit() */}
                        <div style={{ display: currentStep === 0 ? "block" : "none" }}><SignupLandingView /></div>
                        <div style={{ display: currentStep === 1 ? "block" : "none" }}><SignupDealerFormItems /></div>
                        <div style={{ display: currentStep === 2 ? "block" : "none" }}><SignupUserFormItems /></div>
                        <div style={{ display: currentStep === 3 ? "block" : "none" }}><SignupSubscriptionPackages /></div>
                        <div style={{ display: currentStep === 4 ? "block" : "none" }}><SignupCardPaymenSubmit /></div>

                        {/* Honeypot */}
                        <Form.Item className="u5kr7b4r6vg" name={"address"}>
                            <Input />
                        </Form.Item>
                    </Form>
                </LoadingContainer>
            </div>
        </>
    );
};




const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey || '');

type SignUpStepsWrapperProps = {
    userEmail: string | null;
    token: string | null;
}

const SignUpStepsWrapper = ({ userEmail, token }: SignUpStepsWrapperProps) => {
    const [intentCode, setIntentCode] = useState<string>('');
    const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);

    const fetchCode = async () => {
        try {
            setLoadingPaymentIntent(true)
            const { data } = await initSignupPaymentMethodIntent();
            setIntentCode(data.data);
        } catch (error) {
            renderErrorMessage(error)
        } finally { setLoadingPaymentIntent(false) }
    }

    useEffect(() => {
        if (!intentCode) {
            fetchCode();
        }
    }, [intentCode])

    return (
        <SignUpProvider>
            <Loading style={{ height: "100vh" }} loading={loadingPaymentIntent}>
                <Layout style={{ minHeight: "100vh", overflow: "hidden" }} className="signup-bg transition d-flex flex-column align-stretch">
                    <Layout.Header className="bg-none height-auto d-flex justify-content-center" style={{ paddingTop: 48, marginBottom: 96 }}>
                        <img src={Logo} className="position-relative z-4" alt="Dansk Motor Finans A/S" />
                    </Layout.Header>
                    <Layout.Content style={{ position: "relative", zIndex: 3, maxWidth: 968, width: "100%", margin: "auto", paddingBottom: 200, paddingLeft: 14, paddingRight: 14 }}>

                        {stripePromise && intentCode ? (
                            <Elements stripe={stripePromise}
                                options={{ clientSecret: intentCode, locale: "da" }}
                            >
                                <SignUpSteps userEmail={userEmail || ""} token={token || ""} />
                            </Elements>
                        ) : null}

                    </Layout.Content>
                    <div style={{
                        position: "absolute",
                        right: 0,
                        top: 350,
                        transform: "translateX(35%)",
                        maxWidth: 1210,
                        width: '100%',
                        zIndex: 2
                    }} className="mobile-hide">
                        <div style={{
                            position: "absolute",
                            left: "-20%",
                            width: "50%",
                            height: "100%",
                            filter: "blur(100px)",
                            background: "#172633"
                        }}></div>
                        <img src={DashboardImg}
                            style={{
                                width: "100%",
                                height: "auto",
                                mixBlendMode: "soft-light"
                            }}
                            width={1815}
                            height={1131}
                            alt="Dashboard"
                        />
                    </div>
                </Layout>
            </Loading>

        </SignUpProvider>
    )
}
export default SignUpStepsWrapper
