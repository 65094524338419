import { Button } from "antd";
import { useContext } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { SignupContext } from "./signUpProvider";

function SignupLandingView() {
  const { nextStep } = useContext(SignupContext)
  return (
    <div className="text-body-background max-width-720">
      <h1 className="text-body-background font-36" style={{ textShadow: '1px 1px 6px #213548DB' }}>Velkommen til Danskmotorfinans.net</h1>
      <p style={{ textShadow: '1px 1px 6px #213548DB' }}>Vi tilbyder administrative værktøjer til forretningsdrift, herunder bilimport, værdifastsættelse af køretøjer og tilpassede leasingløsninger. For at komme i gang, skal du give os grundlæggende oplysninger og følge de angivne trin. Du får adgang til vores ressourcer for en mere effektiv arbejdsdag. Få en gratis 7-dages prøveperiode. </p>
      <div>
        <p style={{ textShadow: '1px 1px 6px #213548DB' }} className="mb-05"><span className="signup-checkmark mr-05"><Icon name="checkmark-outline" /></span> Hurtig og effektiv sagsbehandling af eksperter på hvert område </p>
        <p style={{ textShadow: '1px 1px 6px #213548DB' }} className="mb-05"><span className="signup-checkmark mr-05"><Icon name="checkmark-outline" /></span> Komplet gennemsigtighed og afsikring af din forretning </p>
        <p style={{ textShadow: '1px 1px 6px #213548DB' }}><span className="signup-checkmark mr-05"><Icon name="checkmark-outline" /></span> Fuldt integreret digital platform for optimal og smidig virksomhedsdrift </p>
      </div>
      <Button className="mt-1" type="primary" onClick={() => nextStep && nextStep()}>
        Start
            </Button>
    </div>
  )
}

export default SignupLandingView;
