import { FC } from "react";
import { Col, Row } from "antd";
import LeasingCaseDealer from "../admin/leasingCaseDealer";
import LeasingCaseHeadExtra from "../leasingCaseHeadExtra";

const LeasingAdminHeadFooter: FC = () => {
  return (
    <Row align="bottom">
      <Col flex="auto">
        <LeasingCaseDealer />
      </Col>
      <Col className="pb-05">
        <LeasingCaseHeadExtra />
      </Col>
    </Row>
  );
};

export default LeasingAdminHeadFooter;
