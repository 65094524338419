import { Alert, Button, Form, Input, message, Space } from "antd";
import { FC, useState } from "react";
import DateSelect from "../../../components/form/dateSelect";
import SwitchInput from "../../../components/form/switchInput";
import ServerSelectBox from "../../../components/serverSelectBox";
import _t from "../../../lang/translate";
import { insuranceCompaniesAutoComplete } from "../../../services/autocompleteService";
import { generateContracts } from "../../../services/leasingService";
import { LeasingCaseType, LeasingContractGenerateType, LeasingPartialCaseType } from "../../../types/leasingTypes";
import consts from "../../../utilities/consts";
import { isAxiosError } from "../../../utilities/typeGuard";

interface LeasingGenerateContractsFormProps {
  leasingCase: LeasingCaseType;
  onCancel: () => void;
  onGenerated: (data: LeasingPartialCaseType) => void;
}

const LeasingGenerateContractsForm: FC<LeasingGenerateContractsFormProps> = ({
  leasingCase,
  onGenerated,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, customer, contract, deal } = leasingCase;

  const handleSubmit = async (values: LeasingContractGenerateType) => {
    try {
      setLoading(true);
      const { data } = await generateContracts(id, values);
      onGenerated(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const showInsuranceSelect = !deal?.insuranceInput?.active;
  const generatableDocuments = contract?.generatableDocuments;

  const initialValues: LeasingContractGenerateType = {
    pbsAccountNumber: customer?.pbsAccountNumber || null,
    pbsRegNumber: customer?.pbsRegNumber || null,
    agreements: contract?.agreements || null,
    insuranceCompanyId: contract?.insuranceCompany?.id || null,
    generateDocuments: null,
  };

  return (
    <Form<LeasingContractGenerateType>
      {...consts.formItemProps}
      requiredMark
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      {showInsuranceSelect && (
        <Form.Item
          name="insuranceCompanyId"
          rules={[{ required: true }]}
          label={_t("insurance_company")}
          className="mb-4"
        >
          <ServerSelectBox disabled={loading} apiUrl={insuranceCompaniesAutoComplete()} />
        </Form.Item>
      )}

      <Form.Item label={_t("PBS_reg_nr")} name="pbsRegNumber" rules={[{ required: true }]}>
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label={_t("PBS_account_nr")} name="pbsAccountNumber" rules={[{ required: true }]} className="mb-4">
        <Input disabled={loading} />
      </Form.Item>

      <Form.Item label={_t("other_information")} className="mb-4" required>
        <span className="d-block mb-05">{_t("expected_start_date")}</span>
        <Form.Item name={["agreements", "expectedStartDate"]} rules={[{ required: true }]}>
          <DateSelect disabled={loading} />
        </Form.Item>
        <span className="d-block mb-05">{_t("extra_agreements")}</span>
        <Form.Item name={["agreements", "extraAgreements"]} rules={[{ required: true }]}>
          <Input disabled={loading} />
        </Form.Item>
        <span className="d-block mb-05">{_t("number_plate_source")}</span>
        <Form.Item name={["agreements", "numberPlateSource"]} rules={[{ required: true }]}>
          <Input disabled={loading} />
        </Form.Item>
        <span className="d-block mb-05">{_t("gps_install_date")}</span>
        <Form.Item name={["agreements", "gpsInstallDate"]} rules={[{ required: true }]}>
          <Input disabled={loading} />
        </Form.Item>
      </Form.Item>
      {generatableDocuments && (
        <Form.Item label={_t("generatable_documents")} className="mb-4">
          {generatableDocuments.map((doc) => {
            const { id, label, checked, toggleable } = doc;
            return (
              <Form.Item
                key={id}
                name={["generateDocuments", id]}
                initialValue={checked}
                valuePropName="checked"
                noStyle
              >
                <SwitchInput label={label} disabled={!toggleable || loading} size="small" className="mb-05" />
              </Form.Item>
            );
          })}
        </Form.Item>
      )}

      <Alert type="warning" className="mb-2" message={_t("msg.generate_contract_lead")} />

      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("generate_contracts")}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default LeasingGenerateContractsForm;
