import { FC, useCallback, useState } from "react";
import { Button, Col, Form, message, Row } from "antd";
import debounce from "lodash/debounce";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { updateVehicle } from "../../../services/leasingService";
import { LeasingVehicleType } from "../../../types/appTypes";
import consts from "../../../utilities/consts";
import { compare } from "../../../utilities/deepCompare";
import LinkBox from "../../../components/form/linkBox";
import SavedStatus from "../../../components/savedStatus";
import LeasingVehicleFormFields from "./leasingVehicleFormFields";
import { isAxiosError } from "../../../utilities/typeGuard";

interface LeasingVehicleFormProps {
  stopEditing: () => void;
}

const LeasingVehicleForm: FC<LeasingVehicleFormProps> = ({ stopEditing }) => {
  const { leasingCase, setLeasingCase, setVehicleLoading } = LeasingStore.useContainer();
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const [vehicleForm] = Form.useForm();
  const vehicleLink = leasingCase.vehicle?.link;
  const flags = leasingCase.flags;

  const handleSave = async () => {
    const vehicle = vehicleForm.getFieldsValue(true);
    try {
      setVehicleLoading(true);
      if (leasingCase.id) {
        await updateVehicle(leasingCase.id, vehicle);
        setLeasingCase({ ...leasingCase, vehicle, activeOffer: null, flags: { ...flags, offerGenerated: false } });
      } else {
        setLeasingCase({ ...leasingCase, vehicle });
      }
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setVehicleLoading(false);
    }
  };

  const handleFormChange = (changedValues: any, values: LeasingVehicleType) => {
    !!leasingCase.id && !!leasingCase.vehicle && delayedSetMatched(values, leasingCase.vehicle);
  };

  const delayedSetMatched = useCallback(
    debounce((values: LeasingVehicleType, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <Form
      {...consts.formItemProps}
      className="m-0"
      onValuesChange={handleFormChange}
      initialValues={leasingCase.vehicle || {}}
      requiredMark={true}
      form={vehicleForm}
      onFinish={handleSave}
    >
      {vehicleLink && <LinkBox link={vehicleLink} />}
      <LeasingVehicleFormFields />
      <Form.Item noStyle shouldUpdate>
        {({ submit, setFieldsValue }) => (
          <Row justify="space-between">
            <Col>
              <Button tabIndex={-1} onClick={stopEditing}>
                {_t("cancel")}
              </Button>
            </Col>
            <Col>
              {!!leasingCase.id && <SavedStatus status={savedStatus} />}
              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default LeasingVehicleForm;
