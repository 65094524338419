import React, { FC, useState } from "react";
import { Checkbox, message, Modal } from "antd";
import ErrorMessage from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { sendImportOffer } from "../../../../services/importService";
import openModal from "../../../../components/modal/openModal";
import { isNum } from "../../../../utilities/typeGuard";
import { ImportCaseType } from "../../../../types/importTypes";

interface ImportSendOfferModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportSendOfferModal: FC<ImportSendOfferModalProps> = ({ visible, setVisible }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { id, files: caseFiles } = importCase;
  const [loading, setLoading] = useState<boolean>(false);
  const [withReport, setWithReport] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);

  const handleReview = async (newCase: ImportCaseType) => {
    const merchantId = newCase.merchant?.id;

    //Open review modal after sending offer:
    if (merchantId) {
      const { data: newRatingScore } = await openModal("review", {
        initialValues: { sourceId: newCase.id, sourceContext: "import" },
        targetId: merchantId,
        targetContext: "merchant",
      }).catch(() => ({} as any));
      // Populate the new rating score:
      if (isNum(newRatingScore) && newCase.merchant) {
        const merchant = { ...newCase.merchant, rating: newRatingScore };
        setImportCase({ ...newCase, merchant });
      }
    }
  };

  const handleOfferSend = async () => {
    try {
      setLoading(true);
      const body = { withReport, files };
      const { data } = await sendImportOffer(id, body);
      message.success(<SavedMessage />);
      setImportCase(data);
      setVisible(false);
      handleReview(data)
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={_t("send", "offer")}
      visible={visible}
      onOk={handleOfferSend}
      confirmLoading={loading}
      cancelButtonProps={{ disabled: loading }}
      onCancel={() => setVisible(false)}
    >
      <p>{_t("add_these_files")}</p>

      <Checkbox disabled={loading} checked={withReport} onChange={({ target }) => setWithReport(target.checked)}>
        {_t("car_report")}
      </Checkbox>

      <Checkbox.Group
        disabled={loading}
        onChange={(v) => setFiles(v)}
        options={caseFiles?.map((file) => ({
          label: file.name,
          value: file.response?.id,
        }))}
      />
    </Modal>
  );
};

export default ImportSendOfferModal;
