import { FunctionComponent } from "react";
import Users from "../screens/users";
import Customers from "../screens/customers";
import Dashboard from "../screens/dashboard";
import NotFound from "../screens/notFound";
import { IconNames } from "../assets/icons/icon-list";
import User from "../screens/forms/user";
import Tax from "../screens/tax";
import TaxCase from "../screens/forms/tax/taxCase";
import Login from "../screens/login";
import Logout from "../screens/logout";
import _t from "../lang/translate";
import Merchants from "../screens/merchants";
import Marchant from "../screens/forms/merchant";
import Posts from "../screens/posts";
import Post from "../screens/forms/post";
import Dealers from "../screens/dealers";
import Dealer from "../screens/forms/dealer";
import Customer from "../screens/forms/customer";
import { isUrlExtraParamsType, ModulTypes } from "../types/appTypes";
import appConfig from "../appConfig";
import System from "../screens/system";
import MyAccount from "../screens/myAccount";

// const permissions = getPermissions();
import { PermissionTypes } from "../types/permissionTypes";
import Leasing from "../screens/leasing";
import LeasingCase from "../screens/forms/leasingCase";
import BillingHistory from "../screens/billingHistory";
import Redirect from "../screens/redirect";
import ImportCases from "../screens/importCases";
import ImportCase from "../screens/forms/import/importCase";
import Statistics from "../screens/statistics";
import SignUp from "../screens/signUp";
import TaxDoc from "../screens/taxDoc";
import TaxDocCase from "../screens/forms/taxDoc/taxDocCase";
import { matchPath } from "react-router-dom";
import BetaBadge from "../components/betaBadge";
import VehicleReports from "../screens/vehicleReports";
import VehicleReportNew from "../screens/vehicleReport";
import VehicleCatalogPage from "../screens/vehicleCatalog/vehicleCatalogPage";
import Subscription from "../screens/subscription";
import ResellerTax from "../screens/resellerTax";
import ResellerTaxCase from "../screens/forms/resellerTax/resellerTaxCase";
import TaxCaseCreated from "../screens/forms/tax/taxCaseCreated";
import LoginFromDMFPro from "../screens/login-from-dmf-pro";


export const paths = {
  home: appConfig("homePath"),
  dashboard: "/dashboard",
  tax: "/tax",
  resellerTax: "/mktax",
  taxDoc: "/afgiftsdokumentation",
  import: "/bilimport",
  leasing: "/leasing-dmf",
  partnerLeasing: "/leasing-kk",
  vehicleReport: "/udstyrsliste",
  dealer: "/forhandler",
  user: "/brugere",
  customer: "/kunder",
  merchant: "/sælgere",
  vehicle: "/køretøj",
  clip: "/klip",
  subscription: "/abonnement",
  purchase: "/betalingsoversigt",
  vehicleCatalog: "/katalog",
  post: "/nyheder",
  system: "/system",
  statistics: "/statistikker",
  myAccount: "/min-konto",
  forgotPassword: "/login/glemt-adgangskode",
  resetPassword: "/login/nulstil-adgangskode",
  login: "/login",
  loginFromDMFPro: '/login-from-dmf-pro',
  signUp: "/opret-konto",
  logout: "/logout",
  redirect: "/r/:id",
  notFound: "/404",
  monthlySpecification: "/monthlySpecification",
};

export const urlExtraParams = {
  new: "ny",
  caseCreated: "sag-oprettet"
};

export const isNew = (id: string | number) => {
  return typeof id === "string" && urlExtraParams.new == id.toLowerCase();
};

export const getLoginMode = (param: string) => {
  return Object.keys(paths).filter((p) => paths[p as ModulTypes].includes(param))[0];
};

export const getPath = (route: ModulTypes, id?: string | number | null): string => {
  const basePath = paths[route];
  if (!basePath) return paths.home;
  let nextId = id;
  if (typeof id === "string" && isUrlExtraParamsType(id)) nextId = urlExtraParams[id];
  return nextId ? `${basePath}/${nextId}` : basePath;
};

export const getTitleByRoute = (route?: ModulTypes | null) => {
  if (route && paths[route]) {
    const routeObj = _routes.filter((item) => typeof item !== "string" && item.path === paths[route]) as RouteObj[];
    return routeObj[0]?.title || null;
  } else return null;
};

export interface RouteObj {
  title: string;
  component: FunctionComponent<any>;
  path: string;
  showInMenu?: boolean | undefined;
  fullscreen?: boolean;
  iconName?: IconNames;
  badge?: FunctionComponent<any>;
  isPublicRoute?: boolean;
  permission?: PermissionTypes;
}

const _routes: Array<RouteObj | string> = [
  {
    title: "Dashboard",
    showInMenu: true,
    component: Dashboard,
    path: getPath("dashboard"),
    iconName: "speedometer-outline",
    permission: "screen.dashboard"
  },
  "divider 1",
  {
    title: "TAX",
    component: TaxCaseCreated,
    path: getPath("tax", "caseCreated"),
    permission: "tax.create", // TODO --> This permission?
    fullscreen: true,
  },
  {
    title: "TAX",
    component: TaxCase,
    path: getPath("tax", ":id"),
    permission: "screen.tax",
    fullscreen: true,
  },
  {
    title: "TAX",
    showInMenu: true,
    component: Tax,
    path: getPath("tax"),
    iconName: "briefcase-outline",
    fullscreen: true,
    permission: "screen.tax_index",
  },
  {
    title: "Afgiftsdokumentation",
    component: TaxDocCase,
    path: getPath("taxDoc", ":id"),
    permission: "screen.taxDoc",
    fullscreen: true,
  },
  {
    title: "Afgiftsdokumentation",
    showInMenu: true,
    badge: BetaBadge,
    component: TaxDoc,
    path: getPath("taxDoc"),
    iconName: "documents-outline",
    fullscreen: true,
    permission: "screen.taxDoc_index",
  },
  {
    title: "Bilimport",
    component: ImportCase,
    path: getPath("import", ":id"),
    permission: "screen.import",
    fullscreen: true,
  },
  {
    title: "Bilimport",
    showInMenu: true,
    component: ImportCases,
    fullscreen: true,
    path: getPath("import"),
    iconName: "archive-outline",
    permission: "screen.import_index",
  },
  {
    title: "Leasing DMF",
    component: LeasingCase,
    path: getPath("leasing", ":id"),
    permission: "screen.leasing",
    fullscreen: true,
  },
  {
    title: "Leasing DMF",
    showInMenu: true,
    component: Leasing,
    path: getPath("leasing"),
    fullscreen: true,
    iconName: "dmf",
    permission: "screen.leasing_index",
  },
  {
    title: "Krone Kapital",
    component: LeasingCase,
    path: getPath("partnerLeasing", ":id"),
    permission: "screen.partner_leasing",
    fullscreen: true,
  },
  {
    title: "Krone Kapital",
    showInMenu: false,
    component: Leasing,
    path: getPath("partnerLeasing"),
    fullscreen: true,
    iconName: "kronekredit-logo",
    permission: "screen.partner_leasing_index",
  },
  {
    title: "Udstyrsliste",
    component: VehicleReportNew,
    path: getPath("vehicleReport", ":id"),
    permission: "screen.vehicle_report",
    fullscreen: true,
  },
  {
    title: "Udstyrsliste",
    showInMenu: true,
    component: VehicleReports,
    path: getPath("vehicleReport"),
    fullscreen: true,
    iconName: "car-sport-outline",
    permission: "screen.vehicle_report_index",
  },
  "divider 2",
  {
    title: "Motorkompagniet",
    component: ResellerTaxCase,
    path: getPath("resellerTax", ":id"),
    permission: "screen.resellerTax",
    fullscreen: true,
  },
  {
    title: "Motorkompagniet",
    showInMenu: true,
    component: ResellerTax,
    path: getPath("resellerTax"),
    fullscreen: true,
    iconName: "mk-logo",
    permission: "screen.resellerTax_index",
  },
  "divider 3",
  {
    title: "Forhandler",
    component: Dealer,
    path: getPath("dealer", ":id"),
    permission: "screen.dealer",
    fullscreen: true,
  },
  {
    title: "Forhandler",
    showInMenu: true,
    component: Dealers,
    path: getPath("dealer"),
    fullscreen: true,
    iconName: "shop-outline",
    permission: "screen.dealer_index",
  },
  {
    title: "Brugere",
    component: User,
    path: getPath("user", ":id"),
    permission: "screen.user",
    fullscreen: true,
  },
  {
    title: "Brugere",
    showInMenu: true,
    component: Users,
    path: getPath("user"),
    iconName: "people-outline",
    permission: "screen.user_index",
    fullscreen: true,
  },
  {
    title: "Kunder",
    component: Customer,
    path: getPath("customer", ":id"),
    permission: "screen.customer",
    fullscreen: true,
  },
  {
    title: "Kunder",
    showInMenu: true,
    component: Customers,
    path: getPath("customer"),
    iconName: "user-list-outline",
    permission: "screen.customer_index",
    fullscreen: true,
  },
  {
    title: "Sælger",
    component: Marchant,
    path: getPath("merchant", ":id"),
    permission: "screen.merchant",
    fullscreen: true,
  },
  {
    title: "Sælger",
    showInMenu: true,
    component: Merchants,
    path: getPath("merchant"),
    iconName: "people-sales",
    fullscreen: true,
    permission: "screen.merchant_index",
  },
  "divider 4",
  {
    title: _t('subscription'),
    showInMenu: true,
    component: Subscription,
    path: getPath("subscription"),
    iconName: "card-outline",
    fullscreen: true,
    permission: "screen.purchase",
  },
  {
    title: "Betalingsoversigt",
    component: BillingHistory,
    path: getPath("purchase"),
    fullscreen: true,
    permission: "screen.purchase_history",
  },
  {
    title: "Katalog",
    component: VehicleCatalogPage,
    path: getPath("vehicleCatalog"),
    fullscreen: true,
    showInMenu: true,
    iconName: "list-outline",
    permission: "screen.vehicle_catalog",
  },
  {
    title: "Nyheder",
    component: Post,
    path: getPath("post", ":id"),
    iconName: "notifications-circle-outline",
    fullscreen: true,
    permission: "screen.post",
  },
  {
    title: "Nyheder",
    showInMenu: true,
    component: Posts,
    path: getPath("post"),
    iconName: "notifications-circle-outline",
    fullscreen: true,
    permission: "screen.post_index",
  },
  {
    title: "System",
    showInMenu: true,
    component: System,
    path: getPath("system"),
    fullscreen: true,
    iconName: "options-outline",
    permission: "screen.system",
  },
  {
    title: "Statistikker",
    showInMenu: true,
    component: Statistics,
    path: getPath("statistics"),
    iconName: "bar-chart-outline",
    permission: "screen.statistics",
    fullscreen: true,
  },
  {
    title: "Min Konto",
    component: MyAccount,
    path: getPath("myAccount"),
    permission: "screen.my_account",
  },
  {
    title: _t("hey_welcome"),
    component: Login,
    path: getPath("login", ":mode?"),
    isPublicRoute: true,
  },
  {
    title: _t("log_in"),
    component: LoginFromDMFPro,
    path: getPath("loginFromDMFPro"),
    isPublicRoute: true,
  },
  {
    title: _t("create", "account"),
    component: SignUp,
    path: getPath("signUp"),
    isPublicRoute: true,
  },
  {
    title: "Logout",
    path: getPath("logout"),
    component: Logout,
    isPublicRoute: true,
  },
  {
    title: "Omdirigering...",
    component: Redirect,
    path: getPath("redirect"),
    isPublicRoute: true,
  },
  {
    title: "Siden ikke fundet",
    component: NotFound,
    path: getPath("notFound"),
    isPublicRoute: true,
  },
];

export const routes = _routes.filter((route) => typeof route != "string") as RouteObj[];

export const isFullscreenRoute = (path: string) => {
  return !!_routes.filter(
    (route) =>
      typeof route != "string" &&
      !!matchPath(path, { path: route.path, exact: true, strict: false }) &&
      route.fullscreen
  ).length;
};

export const menuItems = _routes.filter((route) => typeof route === "string" || route.showInMenu);
