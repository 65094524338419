import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import UserStore from "../contexts/userStore";
import Screen from "../components/screen";
import { LocationStateType } from "../types/appTypes";
import { paths } from "./appRoutes";

interface ProtectedRouteProps extends RouteProps {
  extraProps: { [x: string]: any };
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: Component, children, extraProps, location, ...rest }) => {
  const { user, isAuth } = UserStore.useContainer();
  const fromlocation: LocationStateType = {
    from: { pathname: location?.pathname || "/" },
  };

  if (!isAuth) {
    return <Redirect to={{ pathname: "/login", state: fromlocation }} />
  }

  if (!user?.subscriptionSelected && location?.pathname !== paths.subscription) {
    return <Redirect to={{ pathname: paths.subscription, state: fromlocation }} />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Component ? (
          <Screen>
            <Component key={location?.pathname} {...props} {...extraProps} />
          </Screen>
        ) : undefined
      }
      location={location}
    />
  );
};

export default ProtectedRoute;
