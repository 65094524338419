import { Form, Button, Radio, Space, Row, Col, List } from "antd";
import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import Icon from "../../assets/icons/icon";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import _t from "../../lang/translate";
import { SubscriptionPackageProductCode, SubscriptionPackageType } from "../../types/subscriptionTypes";
import format from "../../utilities/formatNumbers";
import { SignupContext } from "./signUpProvider";

function SignupSubscriptionPackages() {
  const { nextStep, packages, setChosenSubscriptionPackage } = useContext(SignupContext)

  return (
    <div>
      <Space align={"center"} wrap style={{ justifyContent: "space-between", width: "100%", marginBottom: 24 }}>
        <h1>{_t("choose", "subscription")}</h1>
        <Button type="link" href={"https://danskmotorfinans.dk/priser/"} target="_blank">
          {_t("promo.read_about_our_subscriptions_here")}
        </Button>
      </Space>
      <Form.Item name={"packageProductCode"} rules={[{ required: true }]}>
        <Radio.Group className="signup-radio-group">
          {packages?.map((subscriptionPackage) => {
            const displayFourteenDaysFree = subscriptionPackage.productCode !== SubscriptionPackageProductCode.ENTERPRISE &&
              subscriptionPackage.productCode !== SubscriptionPackageProductCode.FREE;
            return (
              <Radio key={subscriptionPackage.level} disabled={!subscriptionPackage.selectable} value={subscriptionPackage.productCode}>
                <h2 className="title">{subscriptionPackage.title}</h2>
                <p className="description">{subscriptionPackage.description}</p>
                <List
                  style={{
                    alignSelf: "flex-start",
                    marginBottom: 16,
                  }}
                  split={false}
                  itemLayout="horizontal"
                  size="small"
                  dataSource={subscriptionPackage.features || []}
                  renderItem={item => (
                    <List.Item className="px-0 pb-0">
                      <Row gutter={8} wrap={false}>
                        <Col className="package-element-visible-xxl">
                          <Icon name="checkmark-outline" className={"text-green"} />
                        </Col>
                        <Col>
                          <ReactMarkdown components={{ p: React.Fragment }}>{item}</ReactMarkdown>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
                <p className="price" style={{ marginBottom: displayFourteenDaysFree ? 0 : 17 }}>{getPrice(subscriptionPackage)}</p>
                {displayFourteenDaysFree && (
                  <small>{_t("promo.first_days_free")}</small>
                )}
              </Radio>
            )
          })}
        </Radio.Group>
      </Form.Item>
      <small style={{ alignSelf: "flex-end" }}>
        {_t("promo.no_payment_or_binding_in_trial_period")}
      </small>
      <Form.Item noStyle shouldUpdate>
        {({ validateFields, getFieldValue }) => {
          const isValid = async () => {
            try {
              await validateFields(["packageProductCode"]); // throws error if invalid
              const packageProductCode = getFieldValue(["packageProductCode"]);
              const chosenPackage = packages?.find(item => item.productCode === packageProductCode);
              setChosenSubscriptionPackage(chosenPackage || null)
              nextStep && nextStep()
            } catch (error) {
              renderErrorMessage(error)
            }
          }
          return (
            <Button type="primary"
              onClick={isValid}
              style={{ float: "right", marginTop: 24 }}
            >
              {_t("continue")}
            </Button>
          )
        }}

      </Form.Item>
    </div>
  )
}

export default SignupSubscriptionPackages;

const getPrice = (subscriptionPackage: SubscriptionPackageType) => {
  const price = subscriptionPackage.priceNoVat;

  if (subscriptionPackage.productCode === SubscriptionPackageProductCode.ENTERPRISE) {
    return _t("contact_us")
  }
  if (subscriptionPackage.productCode === SubscriptionPackageProductCode.FREE) {
    return _t("free");
  }
  if (typeof price === 'string') {
    return <>{price}</>
  }
  return <>
    <span className="position-relative">
      {format.number(price)}
      <span style={{ display: "inline-block", paddingLeft: 2, transform: "translateY(-3px)" }}>,</span>
      <span style={{ marginLeft: -8, }}>-</span>
      <span style={{
        position: "absolute",
        fontSize: 10,
        fontWeight: 300,
        top: 5,
        right: -3,
      }}>md.</span>
    </span>
  </>
}
