import React, { FC, useEffect } from "react";
import { Button, Drawer, Result, Spin } from "antd";
import ChecklistUserStore from "../../contexts/checklistUserStore";
import LayoutStore from "../../contexts/layoutStore";
import consts from "../../utilities/consts";
import ChecklistUserHeader from "./checklistUserHeader";
import { checklistUrl } from "../../services/checklistService";
import { useApi } from "../../services/useApi";
import { ChecklistGroupType } from "../../types/checklistTypes";
import ChecklistUserGroups from "./checklistUserGroups";

const ChecklistAdminContent: FC = () => {
  const {
    groups,
    hideTotal,
    dispatchGroups,
    context,
    id,
    openDrawer,
    closeDrawer,
    drawerVisibility,
    btnText,
    total,
    completedTotal,
  } = ChecklistUserStore.useContainer();
  const { screen } = LayoutStore.useContainer();
  const [{ data, isError, isLoading }, setUrl] = useApi<{ data: ChecklistGroupType[] }>("", { data: [] });

  useEffect(() => {
    dispatchGroups({ type: "populate", payload: data.data });
  }, [data, dispatchGroups]);

  useEffect(() => {
    if (!!id) setUrl(checklistUrl(context, id));
  }, [id, context, setUrl]);

  return (
    <>
      <Button onClick={openDrawer} loading={isLoading}>
        {`${!hideTotal && !isLoading ? `${completedTotal}/${total}` : ""} ${btnText}`}
      </Button>
      <Drawer
        placement="right"
        headerStyle={{ padding: 0, border: 0 }}
        closable={false}
        title={<ChecklistUserHeader />}
        onClose={closeDrawer}
        visible={drawerVisibility}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
      >
        {isError && <Result status="error" />}
        {groups.length === 0 && isLoading && <Spin size="large" />}
        <ChecklistUserGroups />
      </Drawer>
    </>
  );
};

export default ChecklistAdminContent;
