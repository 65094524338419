import React, { FC } from "react";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import { ChecklistGroupType } from "../../types/checklistTypes";
import ChecklistAddEntry from "./checklistAddEntry";
import ChecklistGroupAdminHeader from "./checklistGroupAdminHeader";
import ChecklistAdminItem from "./checklistAdminItem";

interface ChecklistGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  group: ChecklistGroupType;
}

const ChecklistAdminGroup: FC<ChecklistGroupProps> = ({ group, ...rest }) => {
  const { showIgnored, isAdmin, dispatchGroups, groups } = ChecklistAdminStore.useContainer();
  const filteredList = showIgnored ? group.entries : group.entries?.filter((e) => !e.ignored);

  const onDrop = async ({ removedIndex, addedIndex, payload: movedEntry }: DropResult, groupId: string) => {
    if (removedIndex === addedIndex) return;
    const newGroups = [...groups];
    const newGroup = { ...group };
    if (removedIndex !== null) {
      const [item] = newGroup.entries.splice(removedIndex, 1);
      if (addedIndex !== null) {
        newGroup.entries.splice(addedIndex, 0, item);
      }
    } else if (addedIndex !== null) {
      newGroup.entries.splice(addedIndex, 0, { ...movedEntry, groupId });
    }
    const orderdEntries = newGroup.entries.map((entry, i) => ({ ...entry, position: i + 1 }));
    const payload = newGroups.map((g) => (g.id === newGroup.id ? { ...newGroup, entries: orderdEntries } : g));
    dispatchGroups({ type: "populate", payload });
  };
  return (
    <div {...rest}>
      <ChecklistGroupAdminHeader group={group} />
      <ChecklistAddEntry hideButton={!isAdmin} groupId={group.id} />
      <Container
        key={group.id}
        groupName="group"
        onDrop={(e) => onDrop(e, group.id)}
        dragHandleSelector=".drag-handle"
        getChildPayload={(index) => group.entries[index]}
      >
        <>
          {filteredList?.map((item) => (
            <Draggable key={item.id}>
              <div>
                <ChecklistAdminItem item={item} />
                <ChecklistAddEntry hideButton={!isAdmin} groupId={group.id} position={item.position + 1} />
              </div>
            </Draggable>
          ))}
        </>
      </Container>
    </div>
  );
};

export default ChecklistAdminGroup;
