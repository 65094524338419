import { Steps, Tooltip } from "antd";
import { FC, ReactNode } from "react";
import LogText from "../../../components/logText";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { LogObj } from "../../../types/appTypes";

const { Step } = Steps;

const LeasingProcess: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { log, flags } = leasingCase;

  return (
    <div className="overflow-x-auto mx-width-100">
      <Steps progressDot className="mb-1">
        <Step
          title={getTitle(_t("case_created"), getLogDescription(log?.created))}
          status={getStatus(!!leasingCase.id)}
        />
        <Step
          title={getTitle(_t("offer", "created"), getLogDescription(log?.offerGenerated))}
          status={getStatus(flags.offerGenerated)}
        />
        <Step
          title={getTitle(_t("applied"), getLogDescription(log?.validationStarted))}
          status={getStatus(flags.validationStarted)}
        />
        {leasingCase.partnerCase ? (
          <Step
            title={getTitle(
              <>
                {_t("sent", "to")} {_t("partner_leasing")}
              </>,
              getLogDescription(log?.partnerValidationSent)
            )}
            status={getStatus(flags.partnerValidationSent)}
          />
        ) : (
          <>
            <Step
              title={getTitle(_t("validated"), getLogDescription(log?.validationAccepted || log?.validationRejected))}
              status={getStatus(flags.validationStarted && (flags.validationAccepted || flags.validationRejected))}
            />
            <Step
              title={getTitle(_t("contracts_generated"), getLogDescription(log?.contractGenerated))}
              status={getStatus(flags.contractGenerated)}
            />
          </>
        )}
        <Step title={getTitle(_t("active"), getLogDescription(log?.active))} status={getStatus(flags.active)} />
        <Step title={getTitle(_t("expired"), getLogDescription(log?.expired))} status={getStatus(flags.expired)} />
      </Steps>
    </div>
  );
};

export default LeasingProcess;

const getLogDescription = (log?: LogObj | null) => {
  return log ? <LogText className="p-0" noStyle seprator={<br />} logData={log} /> : <em>{_t("pending")}</em>;
};

const getStatus = (value?: boolean) => {
  return value ? "process" : "wait";
};

const getTitle = (title: ReactNode, tooltip: ReactNode) => {
  title = <span className="text-small">{title}</span>;
  if (!tooltip) return title;

  return <Tooltip title={tooltip}>{title}</Tooltip>;
};
