import { UploadFile } from "antd/lib/upload/interface";
import {
  Client,
  EnumItemType,
  Equipment,
  IdType,
  InitialClient,
  LogObj,
  Person,
  RelatedCasesType,
  Vehicle,
} from "./appTypes";
import { MotorAgencyDataType } from "./leasingTypes";

export interface TaxDocListItem {
  id: IdType;
  status: EnumItemType;
  car: string;
  horsePower: string;
  createdAt: string;
  importantNote: string | null;
  registrationFee: number;
  vin: number;
  dealerIsPriority: boolean;
  mileage: number;
  isPriority: boolean;
  clientName: string;
  dealerName: string;
  isQuickCalculation: boolean;
}

const taxDocDataSources = ["vin", "link"] as const;

export type TaxDocDataSourceType = typeof taxDocDataSources[number];

export function isTaxDataSource(key: string): key is TaxDocDataSourceType {
  return taxDocDataSources.includes(key as TaxDocDataSourceType);
}

// api/v1/TaxCase
export interface TaxDocCaseCreation {
  id: IdType;
  isQuickCalculation: boolean;
  vehicle: Vehicle | null;
  note: string | null;
  dataSourceId?: IdType;
  vehicleDataSource: Vehicle | null;
  files: UploadFile[] | null;
  client: InitialClient | null;
  flags: TaxDocFlagsType;
  paymentMethodId: IdType;
  reason?: string | null;
}

export interface TaxDocCase extends TaxDocCaseCreation {
  status: EnumItemType | null;
  importantNote: string | null;
  relatedCases?: RelatedCasesType;
  client: Client | null;
  flags: TaxDocFlagsType;
  log: LogsTaxCase | null;
  links: LinksTaxDoc | null;
  evaluation: EvaluationTaxDocType | null;
  calculation: TaxDocCalculationsType | null;
  settings: TaxDocSettingsType | null;
  documentationNote: string | null;
  documentationFiles: DocumentationFilesType | null;
  motorAgency: MotorAgencyDataType | null;
}

export interface DocumentationFilesType {
  ads?: UploadFile | null;
  vehicleList?: UploadFile | null;
  newPrice?: UploadFile | null;
  equipments?: UploadFile | null;
}

export interface TaxDocCalculationsType {
  documentationDate: string | null;
  tradePrice: TradePriceCalcTaxDocType | null;
  newPrice: NewPriceCalcTaxDocType | null;
  registrationFee: RegFeeCalcTaxDocType | null;
}

export interface TaxDocFlagsType {
  registered: boolean;
  isPriority: boolean;
  forcedStatus: boolean;
  documentationPublished: boolean;
  documentationSent: boolean;
  documentationBought: boolean;
  generatingDocumentation: boolean;
  rejected: boolean;
}

export interface VehicleDataSource {
  typeId: IdType;
  link?: string;
  vin?: string;
  vehicle?: Vehicle;
  isInspected?: boolean;
  mileage?: number;
  useCaseId?: IdType;
}

export interface LogsTaxCase {
  creation: LogObj | null;
  documentationPublished?: LogObj | null;
  documentationSent: LogObj | null;
  documentationBought: LogObj | null;
  rejected: LogObj | null;
  registered: LogObj | null;
}

// api/v1/taxCase/:id/updateStatus
export interface StatusUpdateTax {
  id: IdType;
  forceStatus: boolean;
  autoStatus?: boolean;
}

export interface NewPriceCalcTaxDocType {
  newPriceWithoutEquipments: number | null;
  technicalNewPrice: number | null;
  newPriceSource: string | null;
  newPrice: number | null;
  useTechnicalNewPrice: boolean;
  equipmentsPrice: number | null;
  equipmentCalculator: EquipmentCalculatorType | null;
}

export interface EquipmentCalculatorType {
  equipments: Equipment[] | null;
  euroFactor: number | null;
}

export const initNewPriceInput: NewPriceCalcTaxDocType = {
  newPriceWithoutEquipments: null,
  newPriceSource: null,
  technicalNewPrice: null,
  useTechnicalNewPrice: false,
  newPrice: null,
  equipmentsPrice: null,
  equipmentCalculator: null,
};

export interface TradePriceCalcTaxDocType {
  adVehicles?: any;
  averagePrice: number | null;
  deliveryFee: number | null;
  tradePriceOutput?: any;
  estimatedTradePrice: number | null;
  deductionAverageDiscount: number | null;
  priceLevel: number | null;
  qualityAmount: number | null;
  specialUseAmount: number | null;
  mileageRegulation: number | null;
  equipmentRegulation: number | null;
  otherRegulation: number | null;
  otherRegulationAmount: number | null;
  optimizedRegulation: number | null;
  optimizedRegulationAmount: number | null;
  tradePrice: number | null;
}

export interface RegFeeCalcTaxDocType {
  registrationFee: number | null;
  registrationFeeOutput: RegistrationFeeResults | null;
}

export interface EvaluationTaxDocType {
  tradePrice: number | null;
  newPrice: number | null;
  registrationFee: number | null;
  productPriceVat: number | null;
  totalPrice: number | null;
  productPriceWithVat: number | null;
}

export interface TaxDocSettingsType {
  documentationExpirationDate?: string | null;
  guaranteeLengthInMonths?: number | null;
  productPrice: number | null;
}

export interface CalculationArchiveType extends TaxDocCalculationsType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface TradePriceArchiveType extends TradePriceCalcTaxDocType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface NewPriceArchiveType extends NewPriceCalcTaxDocType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface RegFeeArchiveType extends RegFeeCalcTaxDocType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface LinksTaxDoc {
  terms: string | null;
  documentation: string | null;
  guarantee: string | null;
}

export interface RegistrationFeeResults {
  energyType: {
    id: number | string;
    title: string;
    color: string;
  };
  emission: number;
  batteryCapacity: number;
  valueLoss: number;
  batteryDeduction: number;
  scales: number[];
  grossTax: number;
  emissions: number[];
  emissionAddon: number;
  registrationFee: number;
  bottomDeduction: number;
  taxDeduction: number;
  lowDeduction: number;
  taxableValue: number;
  tax: number;
}

export interface PurchaseType {
  agreedTerms: boolean;
  cardId?: string | null;
  useFreePurchase?: boolean;
}

export interface MakePurchaseType {
  reason: string | null;
}

export interface RejectCaseType {
  reason: string | null;
}

export interface UpdateDocumentationNoteType {
  documentationNote: string;
}

export const initTaxDocCase: TaxDocCase = {
  id: 0,
  isQuickCalculation: true,
  vehicle: null,
  note: null,
  vehicleDataSource: null,
  files: null,
  client: null,
  flags: {
    isPriority: false,
    forcedStatus: false,
    generatingDocumentation: false,
    documentationPublished: false,
    registered: false,
    rejected: false,
    documentationBought: false,
    documentationSent: false,
  },
  paymentMethodId: 1,
  status: null,
  importantNote: null,
  log: null,
  links: null,
  evaluation: null,
  calculation: null,
  documentationNote: null,
  documentationFiles: null,
  motorAgency: null,
  settings: null,
};
