import { Card, Col, Form, message, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Icon from "../assets/icons/icon";
import FancySubmitButton from "../components/fancySubmitButton";
import InputVin from "../components/form/inputVin";
import LoadingContainer from "../components/loadingContainer";
import UserStore from "../contexts/userStore";
import _t from "../lang/translate";
import PageBody from "../layout/pageBody";
import PageTitle from "../layout/pageTitle";
import { getPath, isNew } from "../routes/appRoutes";
import { vehicleReportCreate } from "../services/vehicleReportService";
import { ParamTypes, ScreenProps } from "../types/appTypes";
import { VehicleReportCreateType } from "../types/vehicleReportTypes";
import consts from "../utilities/consts";
import { isAxiosError } from "../utilities/typeGuard";
import VehicleReportAdmin from "./vehicleReport/vehicleReportAdmin";
import { getProductPrices } from "../services/authService";
import DividerLine from "../components/lineSplit";
import CurrentSubscriptionInfo from "../components/CurrentSubscriptionInfo";
import FakeFancySubmitButton from "../components/fakeFancySubmitButton";

const VehicleReportNew: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [creationForm] = Form.useForm<VehicleReportCreateType>();
  const { user, hasPermission, hasEnoughCredits } = UserStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const isAdmin = hasPermission("vehicleReport.isAdmin");

  useEffect(() => {
    if (!isNew(id)) history.replace(getPath("vehicleReport"));
  }, [id, history]);

  const handleNewCase = async () => {
    const values: VehicleReportCreateType = creationForm.getFieldsValue(true);
    try {
      setLoading(true);
      const { data } = await vehicleReportCreate(values);
      if (data.url) window.open(data.url, "_blank");
      setLoading(false);
      history.replace(getPath("vehicleReport"));
    } catch (error) {
      let errorMessage = _t("msg.unknown_error");
      if (isAxiosError(error)) {
        errorMessage = error.response?.data?.message;
        const errors = error.response?.data?.errors;
        errors && creationForm.setFields(errors);
      }
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const initialValues = {
    id: 0,
    vin: null,
    paymentMethodId: 1,
    client: {
      userId: user?.id,
      dealerId: user?.dealer?.id,
      typeId: 1,
    },
  };

  const canPurchase = hasEnoughCredits(getProductPrices()?.vehicleReport || 0);

  const usageText = _t("when_click_calculate_you_use", "credit_from_your_account", ` ${canPurchase.usage.total} `);
  const isEmbeddedAsIframe = window.top !== window.self
  return (
    <>
      <PageTitle title={`${title}/${_t("new")}`} fluid backBtn />
      <PageBody>
        {!isEmbeddedAsIframe && (
          <Row align="top" gutter={24}>
            <Col {...consts.newCaseContentColumnProps(isAdmin)}>
              <Card bordered={false}>
                <CurrentSubscriptionInfo hideDescription={true} />
              </Card>
            </Col>
          </Row>
        )}

        <Form {...consts.formItemProps} initialValues={initialValues} form={creationForm} onFinish={handleNewCase}>
          <Row align="top" gutter={24}>
            {isAdmin && (
              <Col {...consts.newCaseAdminColumnProps}>
                <VehicleReportAdmin loading={loading} />
              </Col>
            )}
            <Col {...consts.newCaseContentColumnProps(isAdmin)}>
              <Card bordered={false} title={_t("fetch", "vehicle_report")}>
                <LoadingContainer loading={loading}>
                  <Form.Item wrapperCol={{ span: 24 }} name="vin" rules={[{ required: true }]}>
                    <InputVin
                      size="large"
                      placeholder={_t("insert_vin_here")}
                      prefix={<Icon name="barcode-outline" />}
                    />
                  </Form.Item>
                  {!isAdmin && (
                    <div className="fancy-btn-wp text-center pt-1">
                      <DividerLine>
                        <small>
                          {!isAdmin && usageText}
                        </small>
                      </DividerLine>
                      {isAdmin || canPurchase.ok ? (
                        <FancySubmitButton loading={loading} disabled={!canPurchase.ok} text={_t('fetch', 'vehicle_report')} />
                      ) : (
                        <FakeFancySubmitButton text={_t('fetch', 'vehicle_report')} />
                      )}
                    </div>
                  )}
                </LoadingContainer>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default VehicleReportNew;
