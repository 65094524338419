import React, { FC, useState } from "react";
import { Button, Col, message, Row, Space } from "antd";
import _t from "../../../../lang/translate";
import ImportMerchantInfo from "./importMerchantInfo";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import ErrorMessage from "../../../../components/messages/errorMessage";
import { downloadDocumentationUrl } from "../../../../services/merchantService";

interface ImportMerchantInfoEditableProps {
  startEditing: () => void;
  startChoosing: () => void;
}

const ImportMerchantInfoEditable: FC<ImportMerchantInfoEditableProps> = ({ startEditing, startChoosing }) => {
  const { importCase } = ImportCaseStore.useContainer();
  const { merchant } = importCase;

  const [loading, setLoading] = useState<boolean>(false);

  const handleDocumentDownload = async () => {
    try {
      setLoading(true);
      const { data } = await downloadDocumentationUrl(merchant?.id || 0);
      if (data.url) window.open(data.url);
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ImportMerchantInfo />
      <Row justify="space-between" align="bottom">
        <Col />
        <Col>
          <Space size="large">
            {!!merchant && (
              <Button type="link" className="pl-0 pr-0 mt-1" onClick={handleDocumentDownload}
                disabled={loading}>
                {_t("download", "documentation")}
              </Button>
            )}
            <Button type="link" className="pl-0 pr-0 mt-1" onClick={startChoosing}>
              {_t("change")}
            </Button>
            <Button type="link" className="pl-0 pr-0 mt-1" disabled={!merchant?.id} onClick={startEditing}>
              {_t("edit")}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default ImportMerchantInfoEditable;
