import React, { FC } from "react";
import { Modal } from "antd";
import NewPriceEquipmentsView from "./newPriceEquipmentsView";
import NewPriceEquipmentsForm from "./newPriceEquipmentsForm";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import { EquipmentCalculatorType } from "../../../../types/taxDocTypes";

interface NewPriceEquipmentsListProps {
  isModalVisible: boolean;
  closeModal: () => void;
  value?: EquipmentCalculatorType | null;
  onChange?: (value: EquipmentCalculatorType | null) => void;
}

const NewPriceEquipmentsList: FC<NewPriceEquipmentsListProps> = ({ isModalVisible, closeModal, value, onChange }) => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const handleApply = (v: { equipmentCalculator: EquipmentCalculatorType }) => {
    onChange && onChange(v.equipmentCalculator);
    closeModal();
  };

  return (
    <>
      <NewPriceEquipmentsView equipments={value?.equipments || []} />
      <Modal
        width={1161}
        title={null}
        visible={isModalVisible}
        destroyOnClose
        closable
        onCancel={closeModal}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <NewPriceEquipmentsForm onApply={handleApply} vin={taxDocCase.vehicle?.vin} initialValues={value} />
      </Modal>
    </>
  );
};

export default NewPriceEquipmentsList;
