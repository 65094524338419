import React, { FC } from "react";
import consts from "../utilities/consts";

const PageBody: FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  return (
    <div style={{ maxWidth: consts.contentMaxWidth, margin: "0 auto" }} {...rest}>
      {children}
    </div>
  );
};

export default PageBody;
