import { Alert, Button } from "antd";
import { FC } from "react";
import AnimateIn from "../../components/animateIn";
import LeasingStore from "../../contexts/leasingStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import LeasingCaseContent from "./leasing/leasingCaseContent";

const LeasingCaseBody: FC = () => {
  const { leasingCase, setLeasingCase, versionStatus, setVersionStatus } = LeasingStore.useContainer();

  const handleUseNewCalculator = () => {
    const nextLeasingCase = { ...leasingCase };
    nextLeasingCase.flags.readonly = false;
    setLeasingCase(nextLeasingCase);
    setVersionStatus("latest");
  };

  return (
    <PageBody>
      <AnimateIn start={!!leasingCase.status} loadingBeforeStart>
        {versionStatus !== "latest" && (
          <Alert
            banner
            type={versionStatus === "expired" ? "error" : "info"}
            message={
              <>
                {_t(versionStatus === "expired" ? "msg.cannot_calculate" : "calc_updated")}
                <Button type="link" className="pt-0 pb-0" size="small" onClick={handleUseNewCalculator}>
                  {_t("use_new_calculator")}
                </Button>
              </>
            }
            closable={versionStatus !== "expired"}
            className="mb-2"
          />
        )}
        <LeasingCaseContent />
      </AnimateIn>
    </PageBody>
  );
};

export default LeasingCaseBody;
