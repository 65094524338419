import { Space } from "antd";
import { FC } from "react";
import DividerLine from "../../../../components/lineSplit";
import LogText from "../../../../components/logText";
import LeasingStore from "../../../../contexts/leasingStore";
import UserStore from "../../../../contexts/userStore";
import _t from "../../../../lang/translate";
import LeasingAdminContrctsInfo from "./leasingAdminContrctsInfo";
import LockCaseButton from "./lockCaseButton";
import OpenContractsButton from "./openContractsButton";
import StartDigitalSigningButton from "./startDigitalSigningButton";
import LeasingGenerateChecklist from "../leasingGenerateChecklist";
import LeasingGenerateBailDocument from "../leasingGenerateBailDocument";
import LeasingGeneratePaymentAdviceRestValueDocument from "../leasingGeneratePaymentAdviceRestValue";
import LeasingGeneratePaymentAdviceFirstTimeDocument from "../leasingGeneratePaymentAdviceFirstTime";

const LeasingAdminContract: FC = () => {
  const { hasPermission } = UserStore.useContainer();
  const { leasingCase } = LeasingStore.useContainer();
  const { flags, log, partnerCase } = leasingCase;
  const canReopenContract = hasPermission(partnerCase ? "partner_leasing.reopen_contract" : "leasing.reopen_contract");

  return (
    <>
      <LeasingAdminContrctsInfo />
      <Space direction="vertical" size="middle">
        {flags.contractGenerated ? (
          <Space split=" - ">
            <span className="text-success">{_t("contracts_generated")}</span>
            {log?.contractGenerated && <LogText logData={log.contractGenerated} />}
          </Space>
        ) : (
          <label>{_t("contracts_not_generated")}</label>
        )}
        <StartDigitalSigningButton />
        {canReopenContract && <OpenContractsButton />}
        <LeasingGenerateChecklist />
        <LeasingGenerateBailDocument />
        <LeasingGeneratePaymentAdviceRestValueDocument />
        <LeasingGeneratePaymentAdviceFirstTimeDocument />
      </Space>
      <DividerLine fluid className="mb-1" />
      <LockCaseButton />
    </>
  );
};

export default LeasingAdminContract;
