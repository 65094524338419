import calculateRegistrationFee from './registrationFeeDecorator';

export interface RegFeeCalcInputType {
  firstRegDate?: string | null;
  isNew: boolean;
  vehicleTypeId?: number | null;
  taxablePrice?: number | null;
  tradePrice?: number | null;
  newPrice?: number | null;
  isHighWeight?: boolean;
  emissionAmount?: number | null;
  emissionUnitId?: number | null;
  electricConsumption?: number | null;
  electricRange?: number | null;
  batteryCapacity?: number | null;
}

export interface RegFeeCalcOutputType {
  vehicleTypeId: number | null;
  energyType: { id: any; title: string };
  emission: number | null;
  batteryCapacity: number | null;
  valueLoss: number | null;
  batteryDeductionAmount: number | null;
  grossTax: number | null;
  emissionAddon: number | null;
  bottomDeductionStandardAmount: number | null;
  taxDeductionAmount: number | null;
  lowDeductionAmount: number | null;
  taxablePrice: number | null;
  registrationFeeBeforeDeduction: number | null;
  registrationFee: number | null;
  scales: (number | null)[];

  //// Extra properties in person bil
  consts?: {
    scaleOneMax: number;
    scaleTwoMax: number;
    emission1Max: number;
    emission2Max: number;
  };
  scaleThreeAmount?: number | null;
  emissions?: (number | null)[];
}

export const initRegFeeOutput: RegFeeCalcOutputType = {
  vehicleTypeId: null,
  energyType: {
    id: 3,
    title: "Nul emissionsbil",
  },
  emission: null,
  batteryCapacity: null,
  valueLoss: null,
  batteryDeductionAmount: null,
  grossTax: null,
  emissionAddon: null,
  bottomDeductionStandardAmount: null,
  taxDeductionAmount: null,
  lowDeductionAmount: null,
  taxablePrice: null,
  registrationFeeBeforeDeduction: null,
  registrationFee: null,
  scales: [],
};

export type CalculateDiffType = (
  estimatedTax: number,
  input: RegFeeCalcInputType,
  isDiff: boolean
) => RegFeeCalcOutputType | number;

export default calculateRegistrationFee;
