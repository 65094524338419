import { Collapse, CollapseProps } from "antd";
import React, { FC } from "react";
import Icon from "../assets/icons/icon";

interface CustomCollapseInterface extends CollapseProps {
  setActiveKey?: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomCollapse: FC<CustomCollapseInterface> = ({ className, setActiveKey, ...rest }) => {
  const togglePanel = (key: string) => {
    setActiveKey &&
      setActiveKey((ap) => {
        if (ap.includes(key)) {
          return ap.filter((k) => k !== key);
        }
        return [...ap, key];
      });
  };

  return (
    <Collapse
      className={`${className ?? ""} custom-collapse`}
      expandIconPosition="right"
      expandIcon={(panelProps: any) => {
        const { panelKey } = panelProps || {};
        return (
          <button className={panelProps.className + "flex-none muted-btn cursor-pointer"} tabIndex={0} onClick={() => togglePanel(panelKey)}>
            <Icon name="chevron-down-outline" size="medium" />
          </button>
        );
      }}
      {...rest}
    />
  );
};

export default CustomCollapse;
