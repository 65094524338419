import { Col, Input, Row, Form, Button, Alert } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useContext, useRef, useState } from "react";
import InputCVR from "../../components/form/inputCVR";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import _t from "../../lang/translate";
import { isCVRInUse } from "../../services/miscService";
import { SignUpFormType } from "../../types/loginTypes";
import { SignupContext } from "./signUpProvider";
import appConfig from '../../appConfig'


function SignupDealerFormItems() {
  const { nextStep, setLoading } = useContext(SignupContext);
  const fetchCvrRef = useRef<HTMLElement>(null);
  const [error, setError] = useState<string | null>(null)
  const dealerFieldsNamePaths: ['dealer', keyof SignUpFormType['dealer']][] = [
    ["dealer", "cvr"],
    ["dealer", "name"],
    ["dealer", "address"],
    ["dealer", "zip"],
    ["dealer", "city"],
    ["dealer", "email"],
    ["dealer", "phone"],
    ["dealer", "billingEmail"],
    ["dealer", "isCarSeller"]
  ]

  return (<>
    {error && (
      <Alert type="error" className="mb-2" message={error}/>
    )}
    <h2 className="mb-4 text-24 text-semibold">Opret din virksomhed i portalen</h2>
    <Row gutter={[{ lg: 72, sm: 36, xs: 24 }, 24]}>
      <Col md={12} span={24}>
        <Form.Item shouldUpdate={(prev, curr) => prev.dealer?.cvr === curr.dealer?.cvr} noStyle>
          {({ setFieldsValue }) => (
            <Form.Item
              label={_t("cvr")}
              name={["dealer", "cvr"]}
              rules={[{ required: true }, { whitespace: true }, { min: 8 }]}
            >
              <InputCVR ref={fetchCvrRef} size="large" onFetched={(dealer) => setFieldsValue({ dealer })} />
            </Form.Item>
          )}
        </Form.Item>
        <h3 className="text-semibold mt-4">Kontaktoplysninger</h3>
        <Form.Item label={_t("company_email")} name={["dealer", "email"]} rules={[{ required: true, type: "email" }]}>
          <Input />
        </Form.Item>
        <Form.Item label={_t("company_phone")} name={["dealer", "phone"]} rules={[{ required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={_t("company_billing_email")} name={["dealer", "billingEmail"]} rules={[{ required: true, type: "email" }]}>
          <Input />
        </Form.Item>
      </Col>

      <Col md={12} span={24} >
        <div className="signup-info-card">
          <Form.Item name={["dealer", "name"]} rules={[{ required: true }, { whitespace: true }]}>
            <Input disabled placeholder={_t("name")} />
          </Form.Item>
          <Form.Item name={["dealer", "address"]} rules={[{ required: true }]}>
            <Input placeholder={_t("address")} disabled />
          </Form.Item>
          <Form.Item name={["dealer", "zip"]} rules={[{ required: true }]}>
            <Input disabled placeholder={_t("zip")} maxLength={10} />
          </Form.Item>
          <Form.Item name={["dealer", "city"]} rules={[{ required: true }]}>
            <Input disabled placeholder={_t("city")} />
          </Form.Item>
        </div>
      </Col>
    </Row>
    <Form.Item className="mt-1"
      name={["dealer", "isCarSeller"]}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(new Error(_t('msg.field_required'))),
        },
      ]}
    >
      <Checkbox>{_t('confirm_car_business')}</Checkbox>
    </Form.Item>
    <Form.Item noStyle dependencies={dealerFieldsNamePaths}>
      {({ validateFields, getFieldValue }) => {
        const validate = async () => {
          try {
            setLoading(true)
            await validateFields(dealerFieldsNamePaths)

            // Check if cvr is already registered in the system
            const cvr = getFieldValue(["dealer", "cvr"])
            const { data } = await isCVRInUse(cvr);
            const cvrAlreadyUsed = data.data;
            if (cvrAlreadyUsed) {
              setError(_t('msg.cvr_already_in_use_contact_dmf') + appConfig('companyEmail') + ` ${_t("or")} ` + appConfig('companyPhone'))
              throw new Error(_t('msg.cvr_already_in_use'));
            }
            setError(null)
            nextStep && nextStep();
          } catch (error) {
            if (error?.values?.dealer && !error?.values?.dealer?.name) {
              fetchCvrRef.current?.click();
            }
            if (error instanceof Error) {
              renderErrorMessage(error)
            }
          } finally {
            setLoading(false)
          }
        }
        return (
          <Button type="primary"
            onClick={validate}
            style={{ float: 'right', marginTop: 24 }}
          >
            {_t("continue")}
          </Button>
        )
      }}
    </Form.Item>
  </>
  )
}

export default SignupDealerFormItems;
