import { Input, Form, Button, Checkbox } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { SignupContext } from "./signUpProvider";
import { PaymentElement } from "@stripe/react-stripe-js";
import { subscriptionPackagePrice } from "../../services/subcriptionService";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import DividerLine from "../../components/lineSplit";
import { PaymentDetails } from "../../components/subscription/form";
import { SubscriptionPackageProductCode } from "../../types/subscriptionTypes";
import appConfig from '../../appConfig'
import { getApiBaseUrl } from '../../services/apiEndpoint'

function SignupCardPaymenSubmit() {
    const { loading } = useContext(SignupContext)
    const subscriptionTerms = getApiBaseUrl(appConfig('static')?.subscriptionTerms);
    const generalTerms = getApiBaseUrl(appConfig('static')?.businessTerms);

    return (<>
        <CardInputForm />
        <Form.Item name={["termsAccepted"]} valuePropName="checked" className="mt-1"
            rules={[
                {
                    validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error(_t('msg.field_required'))),
                },
            ]}
        >
            <Checkbox>
                Jeg bekræfter at have læst og accepteret &nbsp;
                <a href={subscriptionTerms || ""} rel="noreferrer" target="_blank">
                    abonnementsbetingelerne
                </a>
                &nbsp; og &nbsp;
                <a href={generalTerms || ""} rel="noreferrer" target="_blank">
                    de generelle forretningsbetingelser
                </a>

            </Checkbox>
        </Form.Item>
        <Button
            className="fancy-btn mt-1 max-width-240 mx-auto d-block"
            htmlType="submit"
            type="primary"
            block
            disabled={loading}
        >
            {_t("create", "account")}
        </Button>
    </>
    )
}

export default SignupCardPaymenSubmit;

const CardInputForm = () => {
    const { setLoading, chosenSubscriptionPackage, subscriptionPrice, setSubscriptionPrice, usingCard, setUsingCard } = useContext(SignupContext)
    const [wantedCoupon, setWantedCoupon] = useState<string>('')
    const cardIsOptional = chosenSubscriptionPackage?.productCode === SubscriptionPackageProductCode.FREE;

    useEffect(() => {
        if (!cardIsOptional) {
            setUsingCard(true);
        }
    }, [cardIsOptional, setUsingCard])


    // UPDATE THE PRICE TO DISPLAY ON THE SCREEN
    const fetchPrice = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await subscriptionPackagePrice(chosenSubscriptionPackage?.id, wantedCoupon);
            setSubscriptionPrice(data);
            if (wantedCoupon && !data.validCoupon) {
                setWantedCoupon('')
                renderErrorMessage(_t('msg.invalid_coupon'));
            }
        } catch (error) {
            renderErrorMessage(error)
        } finally { setLoading(false) }
    }, [chosenSubscriptionPackage, wantedCoupon, setLoading, setSubscriptionPrice])

    useEffect(() => {
        if (chosenSubscriptionPackage?.id) {
            fetchPrice();
        }
    }, [fetchPrice, wantedCoupon, chosenSubscriptionPackage]);

    return (
        <>
            {subscriptionPrice && chosenSubscriptionPackage && !cardIsOptional && (
                <PaymentDetails newSubPrice={subscriptionPrice} />
            )}


            <div className="mt-2 mb-05">
                <div style={{ display: usingCard ? "block" : "none" }}>
                    <PaymentElement />
                </div>
            </div>

            {cardIsOptional && (
                <Checkbox
                    className="checkbox-btn mt-1"
                    checked={usingCard}
                    onChange={(e) => setUsingCard(e.target.checked)}>
                    Angiv kortoplysninger
                </Checkbox>
            )}

            {usingCard && (<>
                <DividerLine />
                <CouponCode setWantedCoupon={setWantedCoupon} wantedCoupon={wantedCoupon} />
            </>
            )}
        </>
    )
}



type CouponCodeType = {
    setWantedCoupon: React.Dispatch<React.SetStateAction<string>>;
    wantedCoupon: string;
}

const CouponCode = ({ setWantedCoupon, wantedCoupon }: CouponCodeType) => {
    const [showCoupon, setShowCoupon] = useState<boolean>(false);
    const [couponValue, setCouponValue] = useState<string>(wantedCoupon);

    useEffect(() => { setCouponValue(wantedCoupon) }, [wantedCoupon])

    return (
        <>
            {!showCoupon && (
                <Button type="link"
                    onClick={() => setShowCoupon(!showCoupon)}
                    className="p-0"
                >
                    {_t('add', 'couponCode')}
                </Button>
            )}
            {showCoupon && (
                <Input
                    value={couponValue}
                    placeholder={_t('couponCode')}
                    suffix={<Icon name="save-outline" onClick={() => setWantedCoupon(couponValue)} />}
                    onChange={(e) => setCouponValue(e.target.value)}
                />
            )}
        </>
    )
}
