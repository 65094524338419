import { Form, message, Tabs } from "antd";
import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CollapseCard from "../../../components/collapseCard";
import SavedMessage from "../../../components/messages/savedMessage";
import LeasingStore from "../../../contexts/leasingStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { createLeasingCase, updateDeal, } from "../../../services/leasingService";
import consts from "../../../utilities/consts";
import { compare } from "../../../utilities/deepCompare";
import { isAxiosError, isNum } from "../../../utilities/typeGuard";
import LeasingDMFValuesFormFields from "./admin/leasingDMFValuesFormFields";
import leasingCalc from "./calc/leasingCalc";
import { latestVersion } from "./calc/leasingCalcService";
import { LeasingDealType } from "./calc/leasingCalcTypes";
import LeasingAddonsFormFields from "./leasingAddonsFormFields";
import LeasingDealFormFields from "./leasingDealFormFields";
import LeasingEntriesActions from "./leasingEntriesActions";

const { TabPane } = Tabs;

interface LeasingEntriesCardProps {
  savedStatus: boolean,
  setSavedStatus: React.Dispatch<React.SetStateAction<boolean>>,
}

const LeasingEntriesCard: FC<LeasingEntriesCardProps> = ({ savedStatus, setSavedStatus }) => {
  const history = useHistory();
  const {
    leasingCase,
    setLeasingCase,
    leasingForm,
    isAdmin,
    isPrivate,
    setCalcCount,
    setCustomerLoading,
    setVehicleLoading,
    setVersionStatus,
    versionStatus
  } = LeasingStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const isSale = hasPermission(
    leasingCase.partnerCase
      ? "partner_leasing.update_sales_values"
      : "leasing.update_sales_values"
  );

  useEffect(() => {
    calculate();
  }, []);

  /*
    const vehicleMileage = leasingCase.vehicle?.mileage;
    useEffect(() => {
    const isCarWarrantyActive = leasingForm.getFieldValue(["carWarrantyInput", "active"]);
    if (isCarWarrantyActive) {
      calculate();
    }
  }, [vehicleMileage]); */

  const handleUpdate = async (deal: LeasingDealType) => {
    try {
      setLoading(true);
      const { data } = await updateDeal(leasingCase.id, deal);
      setLeasingCase({
        ...leasingCase,
        deal: { ...deal, info: data?.deal?.info || deal.info },
        activeOffer: null,
        flags: { ...leasingCase.flags, offerGenerated: false },
      });
      setSavedStatus(true);
      message.success(<SavedMessage message={_t("saved")} />);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async (deal: LeasingDealType) => {
    try {
      setLoading(true);
      setCustomerLoading(true);
      setVehicleLoading(true);
      const { data } = await createLeasingCase({ ...leasingCase, deal });
      history.replace(
        getPath(leasingCase.partnerCase ? "partnerLeasing" : "leasing", data.id)
      );
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
      setCustomerLoading(false);
      setVehicleLoading(false);
    }
  };

  const handleSubmit = () => {
    let deal: LeasingDealType = {
      ...leasingForm.getFieldsValue(true),
      version: latestVersion,
    };
    if (versionStatus !== "latest") {
      setVersionStatus("latest");
    }
    // Handling vehicle missing data:
    if (!leasingCase.vehicle?.brandId) {
      message.error(_t("msg.vehicle_missing"));
      return;
    }
    // Handling customer missing data:
    if (
      (isPrivate && !leasingCase.customer?.nameFirst) ||
      (!isPrivate && !leasingCase.customer?.name)
    ) {
      message.error(_t("msg.customer_missing"));
      return;
    }
    // Deactivate car warranty if it is active but no packages are selected:
    if (
      deal.carWarrantyInput.active &&
      !deal.carWarrantyInput.selectedPackageId
    ) {
      const carWarrantyInput = { ...deal.carWarrantyInput, active: false };
      leasingForm.setFieldsValue({ carWarrantyInput });
      deal.carWarrantyInput = carWarrantyInput;
    }
    // Deactivate insurance if it is active but not insurable:
    if (deal.insuranceInput.active && !deal.insuranceOutput.insurable) {
      const insuranceInput = { ...deal.insuranceInput, active: false };
      leasingForm.setFieldsValue({ insuranceInput });
      deal.insuranceInput = insuranceInput;
    }

    !leasingCase.id ? handleCreate(deal) : handleUpdate(deal);
  };

  const calculate = useCallback(
    debounce(() => {
      let deal: LeasingDealType = leasingForm.getFieldsValue(true);
      const vehicleAgeInMonths = deal.input?.vehicleAgeInMonths,
        leasingPeriod = deal.input?.leasingPeriod;
      if (
        !isNum(vehicleAgeInMonths) ||
        !leasingPeriod ||
        versionStatus === "expired" ||
        leasingCase.flags.inputLocked ||
        leasingCase.flags.readonly
      )
        return;

      if (!!leasingCase.id) {
        if (deal.insuranceInput.active) {
          deal.insuranceOutput = leasingCalc.insuranceCalc(deal, isPrivate, deal.input.inactivePeriod);
        }
        if (deal.gpsTrackerInput.active) {
          deal.gpsTrackerOutput = leasingCalc.gpsTrackerCalc(deal);
        }
      }
      deal.output = leasingCalc.calc(deal);

      const { gpsTrackerOutput, insuranceOutput, output } = deal;
      leasingForm.setFieldsValue({ gpsTrackerOutput, insuranceOutput, output });
      setCalcCount((v) => v + 1);
      if (leasingCase.deal) {
        const isMatched = compare(leasingCase.deal, deal);
        if (isMatched !== savedStatus) setSavedStatus(isMatched);
      }
    }, 150),
    []
  );

  return (
    <Form
      {...consts.formItemProps}
      onValuesChange={calculate}
      form={leasingForm}
      initialValues={{ ...leasingCase.deal }}
      onFinish={handleSubmit}
    >
      <CollapseCard
        title={_t("entries")}
        loading={loading}
        className="collapse-card-tabs"
      >
        <Tabs defaultActiveKey={"1"}>
          <TabPane tab={_t("entries")} key="1" forceRender>
            <LeasingDealFormFields />
          </TabPane>
          <TabPane
            tab={_t("addons")}
            key="2"
            forceRender={!!leasingCase.id}
            disabled={!leasingCase.id}
          >
            <LeasingAddonsFormFields />
          </TabPane>
          {(isSale || isAdmin) && (
            <TabPane tab={_t("dmf_values")} key="3" forceRender>
              <LeasingDMFValuesFormFields />
            </TabPane>
          )}
        </Tabs>
        <LeasingEntriesActions savedStatus={savedStatus} />
      </CollapseCard>
    </Form>
  );
};

export default LeasingEntriesCard;
