import { Button, Col, Divider, Drawer, Result, Row, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import InfoList from "../../../../components/infoList";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import LayoutStore from "../../../../contexts/layoutStore";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { downloadTaxOffer, getSavedOfferUrl } from "../../../../services/taxService";
import { useApi } from "../../../../services/useApi";
import { ApiResponseObject } from "../../../../types/apiTypes";
import { PrevOfferTax } from "../../../../types/taxTypes";
import consts from "../../../../utilities/consts";
import format from "../../../../utilities/formatNumbers";

interface OfferHistoryDrawerProps {
  optMode?: boolean;
}

const initalData: PrevOfferTax = {
  id: 0,
  tradePrice: null,
  newPrice: null,
  total: null,
  taxablePrice: null,
  registrationFee: null,
  licensePlateFee: null,
  fee: null,
  collateral: null,
  createdAt: null,
  person: null,
};

const TaxOfferArchived: FC<OfferHistoryDrawerProps> = ({ optMode = false }) => {
  const { screen } = LayoutStore.useContainer();
  const { taxCase } = TaxCaseStore.useContainer();
  const [isDownloading, setIsDownloading] = useState(false)
  const [visible, setVisible] = useState<boolean>(false);
  const [{ data, isError, isLoading }, setUrl] = useApi<ApiResponseObject<PrevOfferTax[]>>("", { data: [initalData] });
  const id = taxCase.id;

  useEffect(() => {
    if (visible) setUrl(getSavedOfferUrl(id, optMode));
  }, [visible, setUrl, id, optMode]);

  const openDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  async function downloadOffer(documentId: number) {
    try {
      setIsDownloading(true)
      const offer = await downloadTaxOffer(taxCase.id, documentId)
      if (offer.data.url) window.open(offer.data.url)
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      <Button tabIndex={-1} onClick={openDrawer}>
        <Icon name="file-tray-full-outline" size="medium" /> {_t("archive")}
      </Button>
      <Drawer
        footer={null}
        title={_t("saved_offers")}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
        visible={visible}
        onClose={closeDrawer}
        className="calculation-table"
      >
        {isError && <Result status="error" />}
        {isLoading && <Spin size="large" />}
        {data.data &&
          data.data.map((offer, i) => (
            <Row className="position-relative" gutter={[16, 16]} key={i}>
              <Col span={24}>
                {offer?.person && (
                  <p className="text-center">
                    <strong>{offer.person?.fullName}</strong>
                    <a href={"mailto:" + offer.person.email} className="d-block">
                      {offer.person.email}
                    </a>
                  </p>
                )}
                <InfoList className="bg-white position-relative">
                  <InfoList.Title title={format.dateAndTime(offer?.createdAt)} />
                  <InfoList.Item value={format.price(offer.taxablePrice)} label={_t("taxable_value")} />
                  <InfoList.Item value={format.price(offer.newPrice?.newPrice)} label={_t("new_price")} />
                  <InfoList.Item value={format.price(offer.tradePrice?.tradePrice)} label={_t("trade_price")} />
                  <InfoList.Item
                    value={format.price(offer.registrationFee?.registrationFee)}
                    label={_t("registration_fee")}
                  />
                  <InfoList.Item value={format.price(offer.licensePlateFee)} label={_t("plade_nr_fee")} />
                  <InfoList.Item value={format.price(offer?.fee?.fee)} label={_t("fee")} />
                  {optMode ? (
                    <InfoList.Item value={format.price(offer.collateral)} label={_t("collateral")} />
                  ) : (
                    <InfoList.Item value={format.price(offer?.estimatedCollateral?.estimatedCollateral)} label={_t("estimated", "collateral")} />
                  )}
                  <Divider className="mb-1 mt-2" />
                </InfoList>
                {!optMode && (
                  <Button
                    style={{position: "absolute", right: 0, bottom: 48}}
                    loading={isDownloading}
                    icon={<Icon size="large" name="download-outline" />}
                    onClick={() => downloadOffer(offer.id)}
                  >
                    Hent
                  </Button>
                )}
              </Col>
            </Row>
          ))}
      </Drawer>
    </>
  );
};

export default TaxOfferArchived;
