import React, { FC, useEffect } from "react";
import { Button, Drawer, message, Result } from "antd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import LayoutStore from "../../contexts/layoutStore";
import consts from "../../utilities/consts";
import ChecklistAdminHeader from "./checklistAdminHeader";
import { useApi } from "../../services/useApi";
import { ChecklistGroupType } from "../../types/checklistTypes";
import { checklistUrl, loadDefaultChecklist } from "../../services/checklistService";
import ChecklistAdminGroups from "./checklistAdminGroups";
import ChecklistGroupSort from "./checklistGroupSort";
import EmptyChecklist from "./emptyChecklist";
import { isAxiosError } from "../../utilities/typeGuard";

const ChecklistAdminContent: FC = () => {
  const {
    hideTotal,
    groups,
    drawerVisibility,
    dispatchGroups,
    context,
    id,
    groupSortDrawer,
    closeGroupSortDrawer,
    openDrawer,
    closeDrawer,
    total,
    completedTotal,
    btnText,
  } = ChecklistAdminStore.useContainer();
  const { screen } = LayoutStore.useContainer();

  const [{ data, isError, isLoading }, setUrl, setData] = useApi<{ data: ChecklistGroupType[] }>("", { data: [] });

  useEffect(() => {
    dispatchGroups({ type: "populate", payload: data.data });
  }, [data, dispatchGroups]);

  useEffect(() => {
    if (!!id || !!context) setUrl(checklistUrl(context, id));
  }, [id, context, setUrl]);

  const handleLoadDefaultChecklist = async () => {
    try {
      const { data: nextData } = await loadDefaultChecklist(context, id);
      setData(nextData);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  return (
    <>
      <Button loading={isLoading} onClick={openDrawer}>
        {`${!hideTotal && !isLoading ? `${completedTotal}/${total}` : ""} ${btnText}`}
      </Button>
      <Drawer
        placement="right"
        headerStyle={{ padding: 0, border: 0 }}
        closable={false}
        title={<ChecklistAdminHeader />}
        onClose={closeDrawer}
        visible={drawerVisibility}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
      >
        {isError && <Result status="error" />}
        {groups.length === 0 && !isLoading && <EmptyChecklist onLoadDefaultChecklist={handleLoadDefaultChecklist} />}
        <ChecklistAdminGroups />
        {
          <Drawer
            placement="right"
            onClose={closeGroupSortDrawer}
            title={"Tilpas grouper"}
            visible={groupSortDrawer}
            width={400}
          >
            {groupSortDrawer && <ChecklistGroupSort />}
          </Drawer>
        }
      </Drawer>
    </>
  );
};

export default ChecklistAdminContent;
