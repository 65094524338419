import _t from "../lang/translate";
import { LeasingDealType } from "../screens/forms/leasing/calc/leasingCalcTypes";
import { ChangeClientType, Client, GetStatusesType, IdType, StatusesType, Vehicle, } from "../types/appTypes";
import { CustomerType } from "../types/customerTypes";
import {
  AcceptValidationType,
  CreditValidationLinkType,
  LeasingAdminDealActiveType,
  LeasingAdminDealExpireType,
  LeasingAdminType,
  LeasingCasePaymentAdviceRecipientType,
  LeasingCaseType,
  LeasingCodeUpdateType,
  LeasingContractGenerateType,
  LeasingCreateOfferType,
  LeasingCustomerUpdateType,
  LeasingDeliveryType,
  LeasingFlagsType,
  LeasingInitCaseType,
  LeasingOfferType,
  LeasingPartialCaseType,
  LeasingSendToPartnerValidationType,
  LeasingStartDigitalSigningType,
  LeasingValidationType,
  RejectValidationType,
} from "../types/leasingTypes";
import getApiEndpoint from "./apiEndpoint";
import { getSavedEnums } from "./authService";
import { getInitCustomer } from "./customerService";
import { geStatus } from "./enumService";
import http from "./httpService";
import { mediaApiEndpoint } from "./mediaService";
import { getApiQuery, typeKey } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/leasingCase`;

export function leasingUrl(id: IdType | string) {
  return `${apiEndpoint}/${id}`;
}

export function downloadValuationReportUrl(id: IdType | string) {
  return `${leasingUrl(id)}/downloadValuationReport`;
}

export function leasingCasesUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function cloneLeasingCase(id: IdType) {
  return http.post<{ id: number }>(`${leasingUrl(id)}/toLeasingCase`);
}

export function deleteLeasingCase(id: IdType) {
  return http.delete(leasingUrl(id));
}

// Activities and note:
export function leasingActivitiesUrl(id: IdType) {
  return `${leasingUrl(id)}/activities`;
}

export function saveLeasingActivity(id: IdType, data: any) {
  return http.post(leasingActivitiesUrl(id), data);
}

export function updateLeasingActivity(id: IdType, data: any) {
  return http.put(`${leasingActivitiesUrl(id)}/${data?.id}`, data);
}

export function deleteLeasingActivity(id: IdType, deletedId: string) {
  return http.delete(`${leasingActivitiesUrl(id)}/${deletedId}`);
}

// Filter statuses on table page:

export const getLeasingStatuses: GetStatusesType = (isAdmin = false) => {
  let results: StatusesType = {
    open: { id: -1, title: _t("open") },
  };
  const active = geStatus(8);
  if (active) results = { ...results, active };
  const expired = geStatus(2);
  if (isAdmin && expired) results = { ...results, expired };
  return results;
};

export const getLeasingTypeId = () => {
  const typeIdFromUrl = new URLSearchParams(window.location.search).get(
    typeKey
  );
  return typeIdFromUrl
    ? Number(typeIdFromUrl)
    : getSavedEnums()?.leasingCase?.type[0]?.id || 1;
};

export function isPrivateCustomer(typeId?: number | null) {
  const id = typeId || getInitCustomer().typeId;
  return id === getSavedEnums()?.customer?.type[0]?.id;
}

const initLeasingFlags: LeasingFlagsType = {
  created: false,
  active: false,
  expired: false,
  readonly: false,
  inputLocked: false,
  offerGenerated: false,
  unMergedCustomer: false,
  validationStarted: false,
  validationRejected: false,
  validationAccepted: false,
  invoiceAvailable: false,
  conflictedCustomer: false,
  contractGenerated: false,
  partnerValidationSent: false,
  separateFilesSent: false,
};

export const initLeasingCase: LeasingCaseType = {
  id: 0,
  typeId: 1,
  deal: {} as LeasingDealType,
  vehicle: null,
  customer: null,
  legalOwner: null,
  contactPerson: null,
  activeOffer: null,
  contract: null,
  status: null,
  files: null,
  invoiceStartedAt: null,
  flags: initLeasingFlags,
  importantNote: null,
  log: null,
  partnerCase: true,
  leasingCode: null,
  client: null,
  separateFiles: [],
  sourceCase: null,
  bailee: null,
  paymentAdviceRecipient: null,
  delivery: {
    date: null,
    isReady: false,
  },
  expectedTaxPrice: null,
};

///// Leasing common endpoints:

export function leasingFileEndpoint(id?: IdType) {
  return id ? `${leasingUrl(id)}/file` : mediaApiEndpoint;
}

export function downloadAllFiles(id: IdType) {
  return http.get(`${leasingUrl(id)}/downloadAllFiles`);
}

export function downloadDocumentsUrl(id: IdType) {
  return `${leasingUrl(id)}/downloadDocuments`;
}

export function downloadChecklistUrl(id: IdType) {
  return `${leasingUrl(id)}/downloadChecklist`;
}

export function updateAndDownloadBailDocumentUrl(id: IdType) {
  return `${leasingUrl(id)}/updateAndDownloadBailDocument`;
}

export function createLeasingCase(values: LeasingCaseType) {
  return http.post<LeasingCaseType>(`${apiEndpoint}`, values);
}

export function getInitLeasingCase(values: LeasingInitCaseType) {
  return http.post<LeasingCaseType>(`${apiEndpoint}/init`, values);
}

export function initializeValidation(
  id: IdType,
  values: LeasingValidationType
) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/initializeValidation`,
    values
  );
}

export function updateVehicle(id: IdType, data: Vehicle) {
  return http.put(`${leasingUrl(id)}/vehicle`, data);
}

export function updateCustomer(id: IdType, data: LeasingCustomerUpdateType) {
  return http.put<LeasingPartialCaseType>(
    `${leasingUrl(id)}/updateCustomer`,
    data
  );
}

export function generatePaymentAdviceFirstTimePreview(id: IdType) {
  return http.post(`${leasingUrl(id)}/previewPaymentAdviceFirstTimeDocumentation`);
}

export function sendPaymentAdviceFirstTimeEmail(id: IdType) {
  return http.post(`${leasingUrl(id)}/sendPaymentAdviceFirstTimeDocumentation`);
}

export function generatePaymentAdviceRestValuePreview(id: IdType, values: LeasingCasePaymentAdviceRecipientType) {
  return http.post(
    `${leasingUrl(id)}/previewPaymentAdviceRestValueDocumentation`,
    values
  );
}

export function sendPaymentAdviceRestValueEmail(id: IdType, values: LeasingCasePaymentAdviceRecipientType) {
  return http.post(
    `${leasingUrl(id)}/sendPaymentAdviceRestValueDocumentation`,
    values
  );
}

export function updateDeal(id: IdType, data: LeasingDealType) {
  return http.put<LeasingPartialCaseType>(`${leasingUrl(id)}/updateDeal`, data);
}

export function getConflictedCustomer(id: IdType) {
  return http.get<CustomerType>(`${leasingUrl(id)}/getConflictedCustomer`);
}

export function getOffers(id: number) {
  return http.get<LeasingOfferType[]>(leasingOffersUrl(id));
}

export function leasingOffersUrl(id: number) {
  return `${leasingUrl(id)}/offer`;
}

export function createOffer(id: IdType, data: LeasingCreateOfferType) {
  return http.post<LeasingPartialCaseType>(`${leasingUrl(id)}/offer`, data);
}

export function deleteOffer(id: IdType, offerId: IdType) {
  return http.delete(`${leasingUrl(id)}/offer/${offerId}`);
}

export function sendOffer(id: IdType, offerId: IdType) {
  return http.post<{ data: boolean }>(
    `${leasingUrl(id)}/offer/${offerId}/send`
  );
}

export function activateOffer(id: IdType, offerId: IdType) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/offer/${offerId}/activate`
  );
}

///// Leasing-DMF endpoints:

export function generateContracts(
  id: IdType,
  values: LeasingContractGenerateType
) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/generateContracts`,
    values
  );
}

export function downloadAllContractsEndpoint(id: IdType) {
  return `${leasingUrl(id)}/downloadAllContracts`;
}

export function updateLeasingClient(id: IdType, data: ChangeClientType) {
  return http.put<Client>(`${leasingUrl(id)}/client`, data);
}

///// Leasing partner endpoints:

export function separateFileEndpoint(id: IdType, apiKey: string) {
  return `${leasingUrl(id)}/${apiKey}`;
}

export function leasingCaseToPartnerFile(id: IdType) {
  return http.get<{ url: string }>(`${leasingUrl(id)}/toPartnerFile`);
}

export function sendToPartnerValidation(
  id: IdType,
  data: LeasingSendToPartnerValidationType
) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/sendToPartnerValidation`,
    data
  );
}

///// Leasing admin endpoints:

export function activateLeasingCase(id: IdType) {
  return http.post<LeasingPartialCaseType>(`${leasingUrl(id)}/activate`);
}

export function expireLeasingCase(id: IdType) {
  return http.post<LeasingPartialCaseType>(`${leasingUrl(id)}/expire`);
}

export function mergeCustomer(id: IdType, values: LeasingCustomerUpdateType) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/mergeCustomer`,
    values
  );
}

export function administrationEndpoint(id: IdType) {
  return `${leasingUrl(id)}/administration`;
}

export function getAdministration(id: IdType) {
  return http.get<LeasingAdminType>(`${leasingUrl(id)}/administration`);
}

export function keyNumbersUrl(id: IdType) {
  return `${leasingUrl(id)}/keyNumbers`;
}

export function sendCreditPortalLink(
  id: IdType,
  data: CreditValidationLinkType
) {
  return http.post<{ data: boolean }>(
    `${leasingUrl(id)}/sendCreditPortalLink`,
    data
  );
}

export function acceptValidation(id: IdType, values: AcceptValidationType) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/acceptValidation`,
    values
  );
}

export function rejectValidation(id: IdType, values: RejectValidationType) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/rejectValidation`,
    values
  );
}

export function toggleInputLock(id: IdType, status: boolean) {
  return http.put(`${leasingUrl(id)}/toggleInputLock`, {
    status,
  });
}

export function createDigitalSigningCase(
  id: IdType,
  data: LeasingStartDigitalSigningType
) {
  return http.post<{ data: string }>(
    `${leasingUrl(id)}/createDigitalSigningCase`,
    data
  );
}

export function unGenerateContracts(id: IdType) {
  return http.post<LeasingPartialCaseType>(
    `${leasingUrl(id)}/unGenerateContracts`
  );
}

export function updateDelivery(id: IdType, data: LeasingDeliveryType) {
  return http.put(`${leasingUrl(id)}/updateDelivery`, data);
}

export function updateDealActiveSettings(
  id: IdType,
  data: LeasingAdminDealActiveType
) {
  return http.put<LeasingAdminType>(
    `${leasingUrl(id)}/updateDealActiveSettings`,
    data
  );
}

export function updateDealExpireSettings(
  id: IdType,
  data: LeasingAdminDealExpireType
) {
  return http.put<LeasingAdminType>(
    `${leasingUrl(id)}/updateDealExpireSettings`,
    data
  );
}

export function markAsActiveReport(
  id: IdType,
  motorAgencyReportId: string | null
) {
  return http.put<LeasingAdminType>(`${leasingUrl(id)}/markAsActiveReport`, {
    motorAgencyReportId,
  });
}

export function sendSeparateFilesToPartner(
  id: IdType,
  data: "CreditValidationLinkType"
) {
  return http.post(`${leasingUrl(id)}/sendSeparateFilesToPartner`, data);
}

export function updateLeasingCode(id: IdType, data: LeasingCodeUpdateType) {
  return http.post(`${leasingUrl(id)}/updateLeasingCode`, data);
}

export function checkMotorAgencyStatus(id: IdType) {
  return http.post(`${leasingUrl(id)}/checkMotorAgencyStatus`);
}
