import { Button, Col, Dropdown, Form, FormInstance, Input, Menu, Row, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { FC } from "react";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import { ActivityEntryType, initActivityEntry, SuggestionsType } from "../types/activityTypes";

const { TextArea } = Input;

interface AddActivityFormProps {
  onSubmit: (values: ActivityEntryType) => void;
  form: FormInstance<ActivityEntryType>;
  loading: boolean;
  suggestions?: SuggestionsType[] | null;
}

const AddActivityForm: FC<AddActivityFormProps> = ({ onSubmit, form, loading, suggestions }) => {
  const suggestionsMenu = suggestions && (
    <Menu>
      {suggestions.map((item) => (
        <Menu.Item onClick={() => form.setFieldsValue({ note: item.text })} key={item.label}>
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Form onFinish={onSubmit} form={form} initialValues={initActivityEntry}>
      <Row align="top" gutter={10}>
        <Col flex="auto">
          <Form.Item name="note" noStyle>
            <TextArea
              disabled={loading}
              className="noresize-text-area"
              placeholder={_t("note")}
              maxLength={500}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          </Form.Item>

          <small className="muted d-block mt-05">{_t("maximum _short", "letters", " 500 ")}</small>
        </Col>
        {suggestionsMenu && (
          <Dropdown overlay={suggestionsMenu} placement="topLeft" trigger={["click"]}>
            <Col className="send-note-btn">
              <Button
                loading={loading}
                className="muted"
                type="link"
                icon={<Icon name="bulb-outline" size="large" />}
              />
            </Col>
          </Dropdown>
        )}
      </Row>
      <Row gutter={10} justify="space-between" align="middle" className="mt-2 mb-1">
        <Col>
          <Space>
            <Form.Item name="isImportant" valuePropName="checked" noStyle>
              <Checkbox>{_t("urgent")}</Checkbox>
            </Form.Item>
            <Form.Item name="isPublic" valuePropName="checked" noStyle>
              <Checkbox>{_t("publish")}</Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate={(curr, next) => curr.isPublic !== next.isPublic} noStyle>
              {({ getFieldsValue, setFieldsValue }) => {
                const values = getFieldsValue();
                const { isPublic, send } = values;
                if (!isPublic && send) setFieldsValue({ ...values, send: false });
                return (
                  <Form.Item name="send" valuePropName="checked" noStyle>
                    <Checkbox disabled={!isPublic}>{_t("send")}</Checkbox>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Space>
        </Col>
        <Col>
          <Button htmlType="submit" loading={loading} type="primary" icon={<Icon name="paper-plane-outline" />}>
            {_t("send")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddActivityForm;
