import React, { FC, useEffect, useState } from "react";
import LayoutStore from "./../contexts/layoutStore";
import { Drawer, Form, message, Result, Spin, Timeline } from "antd";
import _t from "../lang/translate";
import { IdType, ProductType } from "../types/appTypes";
import { useApi } from "../services/useApi";
import { ApiResponseObject } from "../types/apiTypes";
import consts from "../utilities/consts";
import { ActivityEntries, ActivityEntryType, initActivites } from "../types/activityTypes";
import AddActivityForm from "./addActivityForm";
import ActivityTimeLineItem from "./activitiesAndNotes/activityTimeLineItem";
import ActivityReloadBtn from "./activitiesAndNotes/ActivityReloadBtn";
import ActivitiesHeader from "./activitiesAndNotes/ActivitiesTitle";
import { deleteActivity, getSuggestions, getUrl, saveActivity, updateActivity } from "../services/activityService";
import { isAxiosError } from "../utilities/typeGuard";

interface ActivityDrawerProps {
  visible: boolean;
  isAdmin?: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  type: ProductType;
  id: IdType;
}

const ActivityDrawer: FC<ActivityDrawerProps> = ({ visible, setVisibility, type, isAdmin, id }) => {
  const { screen } = LayoutStore.useContainer();
  const [form] = Form.useForm<ActivityEntryType>();
  const [{ data, isError, isLoading }, setUrl, setData] = useApi<ApiResponseObject<ActivityEntries>>("", initActivites);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const reloadActivities = () => setUrl(getUrl(type, id) + "?refresh=" + Math.random());

  useEffect(() => {
    if (visible) setUrl(getUrl(type, id));
  }, [visible, type, id, setUrl]);

  const onSubmit = async (data: ActivityEntryType) => {
    try {
      setLoading(true);
      const { data: newData } = await saveActivity(type, id, data);
      setData(newData);
      form.resetFields();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (values: ActivityEntryType) => {
    try {
      setLoading(true);
      const { data: newData } = await updateActivity(type, id, values);
      setData(newData);
      setEditingId(null);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };
  const onDelete = async (deletedId: string) => {
    try {
      setLoading(true);
      const { data: newData } = await deleteActivity(type, id, deletedId);
      setData(newData);
      setEditingId(null);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const suggestions = getSuggestions(type);

  const filteredActivities =
    selectedFilter && selectedFilter > 0
      ? data.data?.entries.filter((item) => item.type.id === selectedFilter)
      : data.data?.entries;

  return (
    <Drawer
      title={
        <ActivitiesHeader loading={loading || isLoading} onFilter={setSelectedFilter} filters={data?.data.filters} />
      }
      footer={isAdmin && <AddActivityForm {...{ form, onSubmit, loading, suggestions }} />}
      placement="right"
      closable={false}
      onClose={() => setVisibility(false)}
      visible={visible}
      width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
    >
      {isError && <Result status="error" />}
      {filteredActivities.length === 0 && isLoading && <Spin size="large" />}
      {filteredActivities.length > 0 && (
        <Timeline>
          <ActivityReloadBtn loading={isLoading} onReload={reloadActivities} />
          {filteredActivities.map((item) => (
            <ActivityTimeLineItem
              {...{
                key: item.id,
                item,
                isAdmin,
                onUpdate,
                onDelete,
                editable: item.type.id !== 2,
                editingId,
                setEditingId,
                loading,
              }}
            />
          ))}
        </Timeline>
      )}
    </Drawer>
  );
};

export default ActivityDrawer;
