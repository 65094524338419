import { Button, message } from "antd";
import React, { FC, useState } from "react";
import Details from "../../../components/detailsList";
import RateInput from "../../../components/rateInput";
import MerchantStore from "../../../contexts/merchantStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { downloadDocumentationUrl } from "../../../services/merchantService";
import ErrorMessage from "../../../components/messages/errorMessage";

interface MerchantInfoViewProps {
  startEditing: () => void;
}

const MerchantInfoView: FC<MerchantInfoViewProps> = ({ startEditing }) => {
  const { merchant } = MerchantStore.useContainer();
  const {
    name,
    email,
    phone,
    address,
    zip,
    country,
    vatNumber,
    city,
    bank,
    iban,
    bic,
    note
  } = merchant || {};

  const [loading, setLoading] = useState<boolean>(false);

  const handleDocumentDownload = async () => {
    try {
      setLoading(true);
      const { data } = await downloadDocumentationUrl(merchant.id || 0);
      if (data.url) window.open(data.url);
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Details borderLess>
        <Details.Item label={_t("name")} value={name || "-"} />
        <Details.Item label={_t("vat_number")} value={vatNumber || "-"} className="mb-1" />
        <Details.Item label={_t("address")} value={address || "-"} />
        <Details.Item label={_t("zip", "city", " & ")} value={format.text(zip, city)} />
        <Details.Item label={_t("country")} value={country || "-"} className="mb-1" />
        <Details.Item label={_t("email")} value={email || "-"} />
        <Details.Item label={_t("phone")} value={phone || "-"} className="mb-1" />
        <Details.Item label={_t("bank")} value={bank || "-"} className="mb-1" />
        <Details.Item label={_t("iban")} value={iban || "-"} className="mb-1" />
        <Details.Item label={_t("bic")} value={bic || "-"} className="mb-1" />
        <Details.Item label={_t("note")} value={note || "-"} />
      </Details>
      <RateInput value={merchant.rating || 0} allowHalf disabled />
      <div className="text-right">
        <Button type="link" className="pl-0 pr-1 mt-1" onClick={handleDocumentDownload}
          disabled={loading}>
          {_t("download", "documentation")}
        </Button>
        <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default MerchantInfoView;
