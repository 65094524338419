import React, { FC, useEffect, useState } from "react";
import { ScreenProps } from "../types/appTypes";
import _t from "../lang/translate";
import { Button, Col, Form, Input, Row } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getPath } from "../routes/appRoutes";
import { ReactComponent as Logo } from "../assets/images/dmf_logo.svg";
import { ReactComponent as LogoTagLine } from "../assets/images/dmf_tagLine.svg";
import { getCurrentUser, signUpInitialize, signUpValidateToken } from "../services/authService";
import { renderErrorMessage } from "../components/messages/errorMessage";
import ContactMenuDropdown from "../components/contactMenu";
import LayoutStore from "../contexts/layoutStore";
import UserStore from "../contexts/userStore";
import appConfig from "../appConfig";
import SignUpStepsWrapper from "./signup/signUpSteps";
import moment from "moment";

const checkTokenExpiry = (validUntil: string) => {
  return moment(validUntil).diff(moment()) > 0;
}

const SignUp: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null)

  useEffect(() => {
    const user = getCurrentUser();
    if (user !== null) history.push(getPath("home"));
  }, [history]);


  useEffect(() => {
    if (token) {
      const validateToken = async () => {
        try {
          const { data } = await signUpValidateToken(token || '');
          if (checkTokenExpiry(data.data.validUntil)) {
            setUserEmail(data.data.email)
            setTokenIsValid(true)
          }
        } catch (error) {
          renderErrorMessage(error)
          history.replace(getPath('login'))
        }
      }
      validateToken();
    }
  }, [token, history])

  return tokenIsValid ? <SignUpStepsWrapper userEmail={userEmail} token={token} /> : <SignUpInitialize title={title} />

};

export default SignUp;


const SignUpInitialize: FC<ScreenProps> = ({ title }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { screen } = LayoutStore.useContainer();
  const isMobile = screen.isMobile;
  const { showCookiePolicy } = UserStore.useContainer();
  const showCookies = !!process.env.REACT_APP_COOKIEBOT_ID;

  const beginSignupProcess = async (values: { email: string }) => {
    try {
      setLoading(true);
      await signUpInitialize(values.email);
      setFormSubmitted(true)
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="login">
      <Col flex={'500px'} className="transition">
        <div className="login-form-wp signup-form-wp">
          <div className="login-mobile-logo">
            <Logo />
          </div>
          <div className="signup-form-wrapper">
            <Button type="link" className="underline-style p-0">
              &lt;
              <Link to={getPath("login")}>{_t("back_to_login")}</Link>
            </Button>
            <h1>{title}</h1>
            {formSubmitted ? (
              <div>
                <h2>{_t("msg.thanks_for_signing_up")}!</h2>
                <p>{_t("msg.activation_link_sent_to_email")}</p>
              </div>
            ) : (
              <Form layout="vertical" onFinish={beginSignupProcess}>
                <Form.Item label={_t("your", "email")} name="email" rules={[{ required: true, type: "email" }]}>
                  <Input disabled={loading} type="email" placeholder="john@doe.com" />
                </Form.Item>
                <Button loading={loading}
                  className="w-100"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Send aktiveringslink
                </Button>
              </Form>
            )}
          </div>

          <div className="login-footer">
            <Row gutter={16} justify={isMobile ? "start" : "space-between"} align="middle">
              <Col>
                <span className="muted">{appConfig("companyName")}</span>
              </Col>
              <Col>
                <ContactMenuDropdown />
              </Col>

              {showCookies && (
                <Col>
                  <Button className="underline-style p-0" type="link" onClick={showCookiePolicy}>
                    {_t("cookie_policy")}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Col>
      <Col flex="auto" className="login-bg">
        <LogoTagLine className="position-relative z-4" />
      </Col>
    </Row>
  );
}
