import { Collapse } from "antd";
import { FC } from "react";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";

const LeasingAdminContrctsInfo: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { agreements } = leasingCase?.contract || {};
  const { expectedStartDate, extraAgreements, gpsInstallDate, numberPlateSource } = agreements || {};
  const disabled = !leasingCase.flags.contractGenerated;

  return (
    <CustomCollapse>
      <Collapse.Panel
        collapsible={disabled ? "disabled" : undefined}
        key="dealer_merchant_relation"
        header={
          <CustomCollapseHeader className={disabled ? "muted" : ""}>{_t("contracts", "info")}</CustomCollapseHeader>
        }
      >
        <label>{_t("expected_start_date")}</label>
        <p className="mb-05">{expectedStartDate || "-"}</p>

        <label>{_t("extra_agreements")}</label>
        <p className="mb-05">{extraAgreements || "-"}</p>

        <label>{_t("number_plate_source")}</label>
        <p className="mb-05">{numberPlateSource || "-"}</p>

        <label>{_t("gps_install_date")}</label>
        <p className="mb-05">{gpsInstallDate || "-"}</p>
      </Collapse.Panel>
    </CustomCollapse>
  );
};

export default LeasingAdminContrctsInfo;
