import { FC } from "react";
import { Alert, Col, Row } from "antd";
import _t from "../../../../lang/translate";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import ConflictAlert from "../../../../components/conflictAlert";
import TaxVehicleEdit from "./taxVehicleEdit/taxVehicleEdit";
import TaxOfferEdit from "./taxOfferEdit";
import TaxActions from "./taxActions";
import TaxAttachments from "./../taxAttachments";
import { getVersionStatus } from "../calc/taxCalcService";
import TaxEstimation from "./taxEstimation/taxEstimation";
import RelatedCaseAlert from "../../../../components/relatedCaseAlert";

const TaxCaseEdit: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const caseCalculatedAtYear = new Date(taxCase.calculation?.createdAt ?? '').getFullYear()
  const versionStatus = getVersionStatus(taxCase.version, caseCalculatedAtYear);
  return (
    <Row align="top" gutter={24}>
      <Col span={24}>
        {versionStatus !== "latest" && (
          <Alert
            banner
            type={versionStatus === "expired" ? "error" : "info"}
            message={_t(
              versionStatus === "expired"
                ? "msg.cannot_calculate"
                : "calc_updated"
            )}
            closable={versionStatus !== "expired"}
            className="mb-2"
          />
        )}
      </Col>
      <Col span={24}>
        {taxCase.client?.dealerNote && (
          <Alert
            type={"info"}
            message={taxCase.client.dealerNote}
            className="mb-2"
          />
        )}
      </Col>
      <Col
        xxl={{ span: 8, order: 1 }}
        xl={{ span: 12, order: 3 }}
        xs={{ span: 24, order: 3 }}
      >
        <TaxVehicleEdit />
      </Col>
      <Col
        xxl={{ span: 8, order: 2 }}
        xl={{ span: 12, order: 1 }}
        xs={{ span: 24, order: 1 }}
      >
        <TaxOfferEdit />
        <TaxOfferEdit optMode />
      </Col>
      <Col
        xxl={{ span: 8, order: 3 }}
        xl={{ span: 12, order: 2 }}
        xs={{ span: 24, order: 2 }}
      >
        <TaxEstimation />
        {taxCase.importantNote && (
          <Alert
            className="mb-2"
            message={taxCase.importantNote}
            type="warning"
            closable
          />
        )}
        {taxCase.relatedResellerTaxCase && (
          <RelatedCaseAlert linkedCase={taxCase.relatedResellerTaxCase} />
        )}
        {taxCase.relatedCases && (
          <ConflictAlert conflictedCases={taxCase.relatedCases} />
        )}
        <TaxActions />
        <TaxAttachments />
      </Col>
    </Row>
  );
};

export default TaxCaseEdit;
